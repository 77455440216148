import React, { useState, useEffect  } from 'react';
import Alert from '@mui/material/Alert';
import {Box, Container} from '@mui/material/';

function Alerts() {

// const initializeState = () =>   (tokens);

  const [showAlert, setShowAlert] = useState(true);
  // const [showAlert, setShowAlert] = useState(true);

  useEffect(() => {
    if (showAlert) {
      const timeout = setTimeout(() => {
        setShowAlert(false);
      }, 2000);

      return () => clearTimeout(timeout);
    }
  }, [showAlert]);

  if (showAlert) {
    return (
      <Box my={3}>
        <Container>
      <Alert variant="outlined" severity="success" >
        You are logged in
      </Alert>
      </Container>
      </Box>
    );
  }

  return null;
}

export default Alerts