// import * as React from 'react';
// import { useTheme } from '@mui/material/styles';
// import Box from '@mui/material/Box';
// import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';
// import CardMedia from '@mui/material/CardMedia';
// import IconButton from '@mui/material/IconButton';
// import Typography from '@mui/material/Typography';
// import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
// import PlayArrowIcon from '@mui/icons-material/PlayArrow';
// import SkipNextIcon from '@mui/icons-material/SkipNext';

// export default function VoiceCard() {
//   const theme = useTheme();

//   return (
//     <Card sx={{ display: 'flex' }}>
//       <Box sx={{ display: 'flex', flexDirection: 'column' }}>
//         <CardContent sx={{ flex: '1 0 auto' }}>
//           <Typography component="div" variant="h5">
//             Live From Space
//           </Typography>
//           <Typography variant="subtitle1" color="text.secondary" component="div">
//             Mac Miller
//           </Typography>
//         </CardContent>
//         <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
//           <IconButton aria-label="previous">
//             {theme.direction === 'rtl' ? <SkipNextIcon /> : <SkipPreviousIcon />}
//           </IconButton>
//           <IconButton aria-label="play/pause">
//             <PlayArrowIcon sx={{ height: 38, width: 38 }} />
//           </IconButton>
//           <IconButton aria-label="next">
//             {theme.direction === 'rtl' ? <SkipPreviousIcon /> : <SkipNextIcon />}
//           </IconButton>
//         </Box>
//       </Box>
//       <CardMedia
//         component="img"
//         sx={{ width: 191 }}
//         image="https://images.pexels.com/photos/1202723/pexels-photo-1202723.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
//         alt="Live from space album cover"
//       />
//     </Card>
//   );
// }

import { useState } from 'react';
import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import flag from '../Images/flag.png'

export default function VoiceCard({name, category, audiomp3}) {
  const theme = useTheme();
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = React.createRef();

  const toggleAudio = () => {
    setIsPlaying(!isPlaying);
    if (!isPlaying) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }
  };

  return (
    <Card sx={{ display: 'flex' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <CardContent sx={{ flex: '1 0 auto' }}>
          <Typography component="div" variant="h5">
          {name}
          </Typography>
          <Typography variant="subtitle1" color="text.secondary" component="div">
          {category}
          </Typography>
        </CardContent>
        <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
          {/* <IconButton aria-label="previous">
            {theme.direction === 'rtl' ? <SkipNextIcon /> : <SkipPreviousIcon />}
          </IconButton> */}
          <IconButton aria-label="play/pause" onClick={toggleAudio}>
            <PlayArrowIcon sx={{ height: 38, width: 38 }} />
          </IconButton>
          {/* <IconButton aria-label="next">
            {theme.direction === 'rtl' ? <SkipPreviousIcon /> : <SkipNextIcon />}
          </IconButton> */}
        </Box>
      </Box>
      <CardMedia
        component="img"
        sx={{ width: 191 }}
        // image="https://pixabay.com/get/gd64cc127641f4e14e5fd9d8ef724c1ce5813782900f5c5acfc32d573010882b5cada7ad0d3bb39ea60664703008e2d8d6f89fcabed8e646c2072eefd5c8dc6f82e124210b0138e48c4d4ecc5e8d32e0f_640.png"
        image={flag}
        // image="https://pixabay.com/get/g8e463f6402941424295b05a698a8ed753a6332c0db5d29c02a1bbf3df629d2222287b5ce9b6fe14bc69fb7742340d3d9ab2af03b00c75d7c21a9762cb225a54ca33fcbc0e2fe0abfe4edd5b9f204956e_640.png"
        // image="https://cdn-icons-png.flaticon.com/512/299/299985.png?w=740&t=st=1676810105~exp=1676810705~hmac=9fce056f87ff826acff28c832bc38e5358a8d9d42ab9dc52bf8664ecba2d0caf"
        alt="Live from space album cover"
      />
      <audio ref={audioRef}>
        <source src={audiomp3} type="audio/mp3" />
      </audio>
    </Card>
  );
}
