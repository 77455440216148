import React, { useState, useMemo, useEffect,useCallback } from 'react'
import { Box,Typography, Link, Stack, Alert, Snackbar, Container, Grid, TextField, Button, Dialog, DialogActions, DialogContent, } from '@mui/material'
import axios from 'axios';
import useLocalStorage from 'use-local-storage';
import { CirclesWithBar } from 'react-loader-spinner';
import VoiceCard from "../Component/VoiceCard"
import SendIcon from '@mui/icons-material/Send';
import { saveAs } from 'file-saver';
// import XLSX from 'xlsx';
var XLSX = require("xlsx")



function SixAutoMated() {

    // const [clientId, setClientId] = useLocalStorage('clientId', '');
    // const [clientSecret, setClientSecret] = useLocalStorage('clientSecret', '');
    // const [accessCode, setAccessCode] = useLocalStorage('accessCode', '');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [elevenLabsApi, setelevenLabsApi] = useLocalStorage('elevenlab', '');
    const [loading, setLoading] = useState(false);
    const [loading1, setLoading1] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [openAiError, setOpenAiError] = useState('');
    const [openAiError1, setOpenAiError1] = useState('');
    const [openAiApi, setOpenAiApi] = useLocalStorage('openai', '');
    const [prompt, setPrompt] = useState('');
    const [conversationHistory, setConversationHistory] = useState([]);
    const [alertSeverity, setAlertSeverity] = useState('success');
    const [titleVideo, setTitleVideo] = useState('');
    const [descriptionVideo, setDescriptionVideo] = useState('');
    const [tagsVideo, setTagsVideo] = useState('');
    const [finalprompt, setFinalPrompt] = useState('');
    const [showBox, setShowBox] = useState(false);


    const [fullWidth, setFullWidth] = useState(true);
    const [maxWidth, setMaxWidth] = useState('md');
    const [fullWidth1, setFullWidth1] = useState(true);
    const [maxWidth1, setMaxWidth1] = useState('md');
    const [open, setOpen] = useState(false);
    const [open1, setOpen1] = useState(false);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [selectedVoice, setSelectedVoice] = useState(null);
    const [loading2, setLoading2] = useState(false);
    // const [loading3, setLoading3] = useState(false);
    // const [messageerror3, setMessageError3] = useState("");
    // const [youtubeVideoUploaded, setYoutubeVideoUploaded] = useState('');
    const [query, setQuery] = useState('');
    const [videos, setVideos] = useState([]);
    const [messageerror, setMessageError] = useState("");
    const [finalVideoDOne, setfinalVideoDOne] = useState("");
    // const [finalVideoDOne, setfinalVideoDOne] = useState("http://res.cloudinary.com/dj3vkkpjf/video/upload/v1680809273/q05x7kut2ssx1qlurcfs.mp4");
    
    const [youtubeVideos, setYoutubeVideos] = useState([
        {
          title: '',
          description: '',
          tags: '',
          video: '',
        }
      ]);

      const [loopValue, setLoopValue] = useState(1);

      console.log(youtubeVideos, "youtubeVideos")
    // const [loopExecuted, setLoopExecuted] = useState(false);


    // console.log(finalprompt, clientId, clientSecret, accessCode, openAiApi, prompt, conversationHistory, "clientId, clientSecret, accessCode, openAiApi, prompt, conversationHistory")

    // useEffect(() => {
    //     const timeout = setTimeout(() => {
    //       setAccessCode("");
    //     }, 3600000); // 1 hour in milliseconds
    
    //     return () => clearTimeout(timeout);
    //   }, [accessCode]);


    const handleSubmit = async () => {

        const fields = [
            // { value: clientId, label: 'Client ID' },
            // { value: clientSecret, label: 'Client Secret' },
            // { value: accessCode, label: 'Access Code' },
            { value: openAiApi, label: 'OpenAI API' },
            { value: prompt, label: 'Prompt' },
            { value: elevenLabsApi, label: 'Eleven Labs Api' },
        ];

        const emptyField = fields.find(field => !field.value);

        if (emptyField) {
            setAlertMessage(`Please add ${emptyField.label}`);
            setAlertSeverity('error');
            setSnackbarOpen(true);
        } else {

            setLoading(true);

            axios
                .post('https://copyvid-drsr2tzggq-nn.a.run.app/chat', {
                    message: prompt,
                    openAiApi: openAiApi,
                    conversationHistory: conversationHistory,
                })
                .then((response) => {
                    console.log(response);
                    // Handle success response
                    
                    const aiResponse = response.data.message;

                    // Update conversation history
                    setConversationHistory([
                        ...conversationHistory,
                        { role: 'user', message: prompt },
                        { role: 'assistant', message: aiResponse },
                    ]);


                    setFinalPrompt(aiResponse);

                    setLoading(false);
                })
                .catch((error) => {
                    console.error(error);
                    setOpenAiError(`Error ${error.response.status}: ${error.response.data.error}`);
                    setLoading(false);
                });
        }
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const sleep = (ms) => {
        return new Promise((resolve) => setTimeout(resolve, ms));
    };



    const handleSubmityoutubeDetails = async (requestBody, updateFunction) => {
        const fields = [
            // { value: clientId, label: 'Client ID' },
            // { value: clientSecret, label: 'Client Secret' },
            // { value: accessCode, label: 'Access Code' },
            { value: openAiApi, label: 'OpenAI API' },
            // { value: prompt, label: 'Prompt' },
            { value: elevenLabsApi, label: 'Eleven Labs Api' },
            { value: finalprompt, label: 'Video Script' },
        ];
    
        const emptyField = fields.find(field => !field.value);
    
        if (emptyField) {
            setAlertMessage(`Please add ${emptyField.label}`);
            setAlertSeverity('error');
            setSnackbarOpen(true);
        } else {
            try {
                setLoading1(true);
                setShowBox(true)
                const response = await axios.post('https://copyvid-drsr2tzggq-nn.a.run.app/chat', requestBody);
                console.log(response);
                // Handle success response
                const aiResponse = response.data.message;
    
                // Update conversation history
                setConversationHistory([
                    ...conversationHistory,
                    { role: 'user', message: prompt },
                    { role: 'assistant', message: aiResponse },
                ]);
    
                updateFunction(aiResponse);
            } catch (error) {
                console.error(error);
                setOpenAiError1(`Error ${error.response.status}: ${error.response.data.error}`);
            } finally {
                setLoading1(false);
            }
        }
    };

    const handleAllRequests = async () => {
        setLoading1(true);
    
        const requestBody1 = {
            // message: `Hi ChatGPT! I'm working on a video script for my YouTube channel and I need help coming up with an engaging title that captures the attention of potential viewers. Please provide me with only one title that is both attention-grabbing and relevant to the content I provide below only one title? Thank you! [${finalprompt}]`,
            message: `I'm working on a video script for my YouTube channel and I need help coming up with an engaging title that captures the attention of potential viewers. Please provide me with only one title that is both attention-grabbing and relevant to the content I provide below only one title. [${finalprompt}]`,
            openAiApi,
            conversationHistory: [],
        };
    
        const requestBody2 = {
            // message: `Hi ChatGPT! I need your help in creating an engaging and captivating description for my YouTube video. The description should cover all the aspects mentioned in the provided text, making sure that it's both interesting and informative. The goal is to entice YouTube users to watch the video and enjoy the content. Here's the text for your reference: [${finalprompt}] .Please generate the best description possible. Thank you!`,
            message: `I need your help in creating an engaging and captivating description for my YouTube video. The description should cover all the aspects mentioned in the provided text, making sure that it's both interesting and informative. The goal is to entice YouTube users to watch the video and enjoy the content. Please include in a format using sub headings and then a paragraph of text for each.And then include the best keywords and best 3 hashtags for this video. Here's the video script for your reference: [${finalprompt}]. Please generate the best description possible. Thank you!`,
            openAiApi,
            conversationHistory: [],
        };
    
        const requestBody3 = {
            // message: `Hi ChatGPT! I'm looking for your assistance in generating relevant and effective tags for my YouTube video. The tags should be related to the content provided in the text below and help in attracting the right audience. My aim is to improve the video's visibility and reach on YouTube. Here's the text for your reference: [${finalprompt}] . Please provide me with a list of suitable tags. Thank you!`,
            message: `I'm looking for your assistance in generating relevant and effective tags for my YouTube video. The tags should be related to the content provided in the video script below and help in attracting the right audience. My aim is to improve the video's visibility and reach on YouTube. Here's the video script for your reference: [${finalprompt}] . Please provide me with a list of up to 10 suitable tags comma separated. Thank you!`,
            openAiApi,
            conversationHistory: [],
        };

        try {
            await handleSubmityoutubeDetails(requestBody1, setTitleVideo);
            await sleep(3000); // 2 seconds delay
            await handleSubmityoutubeDetails(requestBody2, setDescriptionVideo);
            await sleep(3000); // 2 seconds delay
            await handleSubmityoutubeDetails(requestBody3, setTagsVideo);
        } catch (error) {
            console.error(error);
            // Handle error here
        } finally {
            setLoading1(false);
        }
            
    }

    const voicearr = [
        {
            "voice_id": "21m00Tcm4TlvDq8ikWAM",
            "name": "Rachel (american, mellow)",
            "samples": [],
            "category": "conversational",
            "preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/21m00Tcm4TlvDq8ikWAM/dff5d82d-d16d-45b9-ae73-be2ad8850855.mp3",
            "available_for_tiers": [],
            "settings": null
        },
        {
            "voice_id": "AZnzlk1XvdvUeBnXmlld",
            "name": "Domi (american, engaged)",
            "samples": [],
            "category": "dynamic",
            "preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/AZnzlk1XvdvUeBnXmlld/53bd2f5f-bb59-4146-9922-245b2a466c80.mp3",
            "available_for_tiers": [],
            "settings": null
        },
        {
            "voice_id": "EXAVITQu4vr4xnSDxMaL",
            "name": "Bella (American, soft)",
            "samples": [],
            "category": "conversational",
            "preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/EXAVITQu4vr4xnSDxMaL/53bd2f5f-bb59-4146-8822-245b2a466c80.mp3",
            "available_for_tiers": [],
            "settings": null
        },
        {
            "voice_id": "ErXwobaYiN019PkySvjV",
            "name": "Antoni (American, modulated)",
            "samples": [],
            "category": "conversational",
            "preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/ErXwobaYiN019PkySvjV/53bd2f5f-bb59-1111-8822-225b2a466c80.mp3",
            "available_for_tiers": [],
            "settings": null
        },
        {
            "voice_id": "MF3mGyEYCl7XYWbV9V6O",
            "name": "Elli (american, clear)",
            "samples": [],
            "category": "dynamic",
            "preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/MF3mGyEYCl7XYWbV9V6O/bea2dc16-9abf-4162-b011-66531458e022.mp3",
            "available_for_tiers": [],
            "settings": null
        },
        {
            "voice_id": "TxGEqnHWrfWFTfGW9XjX",
            "name": "Josh (american, silvery)",
            "samples": [],
            "category": "conversational",
            "preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/TxGEqnHWrfWFTfGW9XjX/bdc4303c-a20d-4cec-97eb-dca625044eac.mp3",
            "available_for_tiers": [],
            "settings": null
        },
        {
            "voice_id": "VR6AewLTigWG4xSOukaG",
            "name": "Arnold (american, nasal)",
            "samples": [],
            "category": "conversational",
            "preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/VR6AewLTigWG4xSOukaG/2c4395e7-91b1-44cd-8f0f-e4aebd292461.mp3",
            "available_for_tiers": [],
            "settings": null
        },
        {
            "voice_id": "pNInz6obpgDQGcFmaJgB",
            "name": "Adam (American, clear)",
            "samples": [],
            "category": "conversational",
            "preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/pNInz6obpgDQGcFmaJgB/53bd2f5f-bb59-1111-8822-245b2a466c80.mp3",
            "available_for_tiers": [],
            "settings": null
        },
        {
            "voice_id": "yoZ06aMxZJJ28mfd3POQ",
            "name": "Sam (american, dynamic)",
            "samples": [],
            "category": "dynamic",
            "preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/yoZ06aMxZJJ28mfd3POQ/1c4d417c-ba80-4de8-874a-a1c57987ea63.mp3",
            "available_for_tiers": [],
            "settings": null
        }
    ]

    const handleChangeVideos = (event) => {
        console.log("event.target.value", event.target.value)
        setQuery(event.target.value);
    };

    const handleSearch = async () => {
        setLoading1(true);

        try {
            // Call the Pexels API
            const pexelsResponse = await axios.get('https://api.pexels.com/videos/search', {
                headers: {
                    Authorization: 'PHTBUSIj55aRxjUWtGHTFcQdnhinQusTl1V3swY4YHmSfADbIRbmDQ2u',
                },
                params: {
                    query: query,
                    page: 1,
                    per_page: 20,
                },
            });

            // Call the Pixabay API
            const pixabayResponse = await axios.get('https://pixabay.com/api/videos/', {
                params: {
                    // key: '33799595-4e9acaeecee2ae409bad61af3',
                    key: '34485150-83c753ff4c0d51c0fa6bd116b',
                    q: query,
                    page: 1,
                    per_page: 20
                },
            });

            // Merge the responses and set the videos state
            const mergedResponse = [...pexelsResponse.data.videos, ...pixabayResponse.data.hits];
            console.log(mergedResponse)
            setVideos(mergedResponse);

            setLoading1(false);

        } catch (error) {
            console.log(error);
            setLoading1(false);
        }
    };

    const handleFinalSubmit = () => {

        if (selectedVoice && elevenLabsApi && selectedVideo) {



            let finallink;
            if (selectedVideo.videos?.large?.url) {
                finallink = selectedVideo.videos.large.url;
                console.log(finallink, "finallink is")
            } else {
                const videoFiles = selectedVideo.video_files;
                console.log(videoFiles);
                videoFiles.sort((a, b) => b.width - a.width);
                const preferredWidths = [1920, 1280, 960];
                const preferredFile = videoFiles.find((file) =>
                    preferredWidths.includes(file.width)
                );
                finallink = preferredFile?.link || videoFiles[0].link;
            }
            //  
            console.log(finallink, "finallink");
            setLoading2(true);
            setfinalVideoDOne("")
            axios
                .post("https://copyvid-drsr2tzggq-nn.a.run.app/fullvideo", {
                    voiceIdDone: selectedVoice,
                    textDone: finalprompt,
                    videoUrl: finallink,
                    elevenLabsApi,
                })
                .then((response) => {
                    // console.log(response);
                    console.log(response.data.url);

                console.log(youtubeVideos, "under then")

                    const newVideo = {
                        title: titleVideo,
                        description: descriptionVideo,
                        tags: tagsVideo,
                        // video: finalVideoDOne,
                        video: response.data.url,
                      };
                      setYoutubeVideos(prevVideos => [...prevVideos, newVideo]);
                    setfinalVideoDOne(response.data.url);

                    setLoopValue(loopValue + 1)
                    setLoading2(false);
                    setMessageError("")
                })
                .catch((error) => {
                    console.log(error);
                    setLoading2(false);
                    setMessageError(`Error ${error.response.status}: ${error.response.data.error}`);
                });
        } else {
            setLoading2(false);
        }
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose2 = () => {
        setOpen(false);
    };


    const handleClickOpen1 = () => {
        setOpen1(true);
    };

    const handleClose1 = () => {
        setOpen1(false);
    };


    // const completeRequestBody = useMemo(() => ({
    //     title: titleVideo,
    //     description: descriptionVideo,
    //     tags: tagsVideo,
    //     accessToken: accessCode,
    //     CLIENT_ID: clientId,
    //     CLIENT_SECRET: clientSecret,
    //     videoUrl: finalVideoDOne,
    //   }), [finalVideoDOne, clientSecret, clientId, accessCode,tagsVideo, descriptionVideo, titleVideo ]);
    


    // const handleSubmitYoutube = async () => {

   
    
    
    //     try {
    //       setLoading3(true)
    //       const response = await axios.post('https://keywordintent-m5tfx6i6ia-nn.a.run.app/uploadyoutubevideo', completeRequestBody);
    //       setYoutubeVideoUploaded(response.data.uploadedVideoUrl);
    //       setLoading3(false)
    //     //   setLoopExecuted(true);
    //     } catch (error) {
    //       console.error(error);
    //       setMessageError3(` ${error.response.data.error}`);
    //       setLoading3(false)
    //     }
    //   };


    //   useEffect(() => {
    //    if(finalVideoDOne.length > 0){
    //     handleSubmitYoutube();
        
    //    }
         
    //   }, [finalVideoDOne]);

    // useEffect(() => {
    //     if (finalVideoDOne.length > 0) {
    //         setLoading3(true)
    //       setTimeout(() => {
    //         handleSubmitYoutube();
    //       }, 7000);
      
          
    //     }
    //   }, [finalVideoDOne]);
      


    //   useEffect(() => {
    //     if (loopExecuted) {
       
    //       for (let i = 0; i < 4; i++) {


    //       }

    //       setLoopExecuted(false);
    //     }
    //   }, [loopExecuted]);


    const downloadExcel = () => {
        // Create a workbook
        const workbook = XLSX.utils.book_new();
        // Create a sheet
        const sheet = XLSX.utils.json_to_sheet(youtubeVideos);
        // Add the sheet to the workbook
        XLSX.utils.book_append_sheet(workbook, sheet, 'YouTube Videos');
        // Convert the workbook to a binary object
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        // Save the file using file-saver
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(blob, 'youtube-videos.xlsx');
      };
      
    //   useEffect(() => {
    //     const handleLoopValueChange = () => {
    //       if (loopValue >= 2 && loopValue <= 7) {
    //         const fields = [        { value: openAiApi, label: 'OpenAI API' },        { value: prompt, label: 'Prompt' },        { value: elevenLabsApi, label: 'Eleven Labs Api' },      ];
      
    //         const emptyField = fields.find(field => !field.value);
      
    //         if (emptyField) {
    //           setAlertMessage(`Please add ${emptyField.label}`);
    //           setAlertSeverity('error');
    //           setSnackbarOpen(true);
    //         } else {
    //           setLoading(true);
      
    //           axios
    //             .post('https://copyvid-drsr2tzggq-nn.a.run.app/chat', {
    //               message: prompt,
    //               openAiApi: openAiApi,
    //               conversationHistory: conversationHistory,
    //             })
    //             .then((response) => {
    //               console.log(response);
    //               const aiResponse = response.data.message;
    //               setLoopValue(loopValue + 1)
      
    //               setConversationHistory([
    //                 ...conversationHistory,
    //                 { role: 'user', message: prompt },
    //                 { role: 'assistant', message: aiResponse },
    //               ]);
      
    //               setFinalPrompt(aiResponse);
      
    //               setLoading(false);
    //             })
    //             .catch((error) => {
    //               console.error(error);
    //               setOpenAiError(`Error ${error.response.status}: ${error.response.data.error}`);
    //               setLoading(false);
    //             });
    //         }
    //       }
    //     }
      
    //     handleLoopValueChange();
    //   }, [finalVideoDOne, loopValue]);
      
     
const videoTwoScript = "Can you write a video script for the 2nd video with just video script and without scene suggestions?";
const videoThreeScript = "Can you write a video script for the 3rd video with just video script and without scene suggestions?";
const videoFourScript = "Can you write a video script for the 4th video with just video script and without scene suggestions?";
const videoFiveScript = "Can you write a video script for the 5th video with just video script and without scene suggestions?";
const videoSixScript = "Can you write a video script for the 6th video with just video script and without scene suggestions?";

// useEffect(() => {
//   const handleLoopValueChange = async () => {
//     if (loopValue >= 2 && loopValue <= 7) {
//       const fields = [
//         { value: openAiApi, label: 'OpenAI API' },
//         { value: videoTwoScript, label: 'Prompt' },
//         { value: elevenLabsApi, label: 'Eleven Labs Api' },
//       ];

//       const emptyField = fields.find(field => !field.value);

//       if (emptyField) {
//         setAlertMessage(`Please add ${emptyField.label}`);
//         setAlertSeverity('error');
//         setSnackbarOpen(true);
//       } else {
//         setLoading(true);

//         let promptMessage;

//         switch (loopValue) {
//           case 2:
//             promptMessage = videoTwoScript;
//             break;
//           case 3:
//             promptMessage = videoThreeScript;
//             break;
//           case 4:
//             promptMessage = videoFourScript;
//             break;
//           case 5:
//             promptMessage = videoFiveScript;
//             break;
//           case 6:
//             promptMessage = videoSixScript;
//             break;
//           default:
//             // handle unexpected loopValue here
//         }

//         axios
//           .post('https://copyvid-drsr2tzggq-nn.a.run.app/chat', {
//             message: promptMessage,
//             openAiApi: openAiApi,
//             conversationHistory: conversationHistory,
//           })
//           .then((response) => {
//             console.log(response);
//             const aiResponse = response.data.message;
//             setLoopValue(loopValue + 1)

//             setConversationHistory([
//               ...conversationHistory,
//               { role: 'user', message: promptMessage },
//               { role: 'assistant', message: aiResponse },
//             ]);

//             setFinalPrompt(aiResponse);

//             setLoading(false);

//             await sleep(2000);
//             handleAllRequests();

//           })
//           .catch((error) => {
//             console.error(error);
//             setOpenAiError(`Error ${error.response.status}: ${error.response.data.error}`);
//             setLoading(false);
//           });
//       }
//     }
//   }

//   handleLoopValueChange();
// }, [finalVideoDOne, loopValue]);


useEffect(() => {
    const handleLoopValueChange = async () => {
      if (loopValue >= 2 && loopValue <= 7) {
        const fields = [
          { value: openAiApi, label: 'OpenAI API' },
          { value: videoTwoScript, label: 'Prompt' },
          { value: elevenLabsApi, label: 'Eleven Labs Api' },
        ];
  
        const emptyField = fields.find(field => !field.value);
  
        if (emptyField) {
          setAlertMessage(`Please add ${emptyField.label}`);
          setAlertSeverity('error');
          setSnackbarOpen(true);
        } else {
          setLoading(true);
  
          let promptMessage;
  
          switch (loopValue) {
            case 2:
              promptMessage = videoTwoScript;
              break;
            case 3:
              promptMessage = videoThreeScript;
              break;
            case 4:
              promptMessage = videoFourScript;
              break;
            case 5:
              promptMessage = videoFiveScript;
              break;
            case 6:
              promptMessage = videoSixScript;
              break;
            default:
              // handle unexpected loopValue here
          }
  
          try {
            const response = await axios.post('https://copyvid-drsr2tzggq-nn.a.run.app/chat', {
              message: promptMessage,
              openAiApi: openAiApi,
              conversationHistory: conversationHistory,
            });
  
            console.log(response);
            const aiResponse = response.data.message;
            setLoopValue(loopValue + 1)
  
            setConversationHistory([
              ...conversationHistory,
              { role: 'user', message: promptMessage },
              { role: 'assistant', message: aiResponse },
            ]);
  
            setFinalPrompt(aiResponse);
  
            setLoading(false);
  
            setTimeout(() => {
              handleAllRequests();
            }, 3000);
          } catch (error) {
            console.error(error);
            setOpenAiError(`Error ${error.response.status}: ${error.response.data.error}`);
            setLoading(false);
          }
        }
      }
    }
  
    handleLoopValueChange();
  }, [finalVideoDOne, loopValue]);
  





    return (

        <>
            <Box className="mainBox" mt={2}>
                <Box pb={5}>
                    <Container maxWidth="xl">

                        <Box className='firstBox'>
                            <Grid container spacing={1}>
                                {/* <Grid item xs={3}>
                                    <TextField label="Client ID" value={clientId} onChange={(e) => setClientId(e.target.value)} fullWidth />
                                </Grid> */}
                                {/* <Grid item xs={3}>
                                    <TextField label="Client Secret" value={clientSecret} onChange={(e) => setClientSecret(e.target.value)} fullWidth />
                                </Grid> */}
                                {/* <Grid item xs={2}>
                                    <TextField label="Access Code" value={accessCode} onChange={(e) => setAccessCode(e.target.value)} fullWidth />
                                </Grid> */}


                                
                                <Grid item xs={4}>
                                    <TextField label="Prompt" value={prompt} onChange={(e) => setPrompt(e.target.value)} fullWidth />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField label="OpenAI API" value={openAiApi} onChange={(e) => setOpenAiApi(e.target.value)} fullWidth />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField label="ElevenLabs Api" variant="outlined" style={{ width: '100%', }} value={elevenLabsApi} onChange={(event) => setelevenLabsApi(event.target.value)} required />

                                </Grid>
                                <Grid item xs={2}>
                                    <Button onClick={handleSubmit} variant="contained" style={{ height: "100%", width: "100%" }}>Generate Script</Button>
                                </Grid>
                                <Grid item xs={2}>
                                    <Button onClick={handleAllRequests} variant="contained" style={{ height: "100%", width: "100%" }}>
                                        Start
                                    </Button>
                                </Grid>

                                {/* <Grid item xs={2}>
                                    <Button onClick={downloadExcel} variant="contained" style={{ height: "100%", width: "100%" }}>
                                        Start
                                    </Button>
                                </Grid> */}

                            </Grid>
                            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleClose}>
                                <Alert onClose={handleClose} severity={alertSeverity} sx={{ width: '100%' }}>
                                    {alertMessage}
                                </Alert>
                            </Snackbar>
                        </Box>

                        {loading ? <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" style={{ minHeight: '50vh' }} >
                            <Grid item xs={12}>
                                <CirclesWithBar color="#4868ff" ariaLabel="falling-lines-loading" />
                            </Grid> </Grid> : null}


                        <Box style={{ width: '100%', }} mt={3}>

                            <TextField
                                id="outlined-multiline-flexible" style={{ width: '100%' }}
                                label="Video Script"
                                multiline
                                value={finalprompt}
                                onChange={(e) => setFinalPrompt(e.target.value)}
                                rows={8}

                            />
                        </Box>

                        {openAiError.length > 0 ? (
                            <Box mt={5} display="flex" justifyContent="center">
                                <Box className='errorHeading'> {openAiError}</Box>
                            </Box>
                        ) : null
                        }


                        {loading1 ? <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" style={{ minHeight: '33vh' }} >
                            <Grid item xs={12}>
                                <CirclesWithBar color="#4868ff" ariaLabel="falling-lines-loading" />
                            </Grid> </Grid> : null}

                        {openAiError1.length > 0 ? (
                            <Box mt={5} display="flex" justifyContent="center">
                                <Box className='errorHeading'> {openAiError1}</Box>
                            </Box>
                        ) : null
                        }

                        {showBox && (

                            <Box className="secondBox">
                                <Grid container spacing={1}>
                                    <Grid item xs={5}>
                                        <Box style={{ width: '100%', }} mt={3}>

                                            <TextField
                                                id="outlined-multiline-flexible" style={{ width: '100%' }}
                                                label="Title"
                                                multiline
                                                value={titleVideo}
                                                onChange={(e) => setTitleVideo(e.target.value)}
                                                rows={8}

                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <Box style={{ width: '100%', }} mt={3}>

                                            <TextField
                                                id="outlined-multiline-flexible" style={{ width: '100%' }}
                                                label="Description"
                                                multiline
                                                value={descriptionVideo}
                                                onChange={(e) => setDescriptionVideo(e.target.value)}
                                                rows={8}

                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Box style={{ width: '100%', }} mt={3}>

                                            <TextField
                                                id="outlined-multiline-flexible" style={{ width: '100%' }}
                                                label="Tags"
                                                multiline
                                                value={tagsVideo}
                                                onChange={(e) => setTagsVideo(e.target.value)}
                                                rows={8}

                                            />
                                        </Box>
                                    </Grid>
                                </Grid>

                                <Box mt={2} pb={3}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={5}>

                                            <Button variant="outlined" onClick={handleClickOpen} style={{ width: '100%', height: '100%' }}>
                                                Select Video
                                            </Button>
                                            <Dialog
                                                fullWidth={fullWidth}
                                                maxWidth={maxWidth}
                                                open={open}
                                                onClose={2}
                                            >

                                                <DialogContent>


                                                    <Grid container spacing={1}>
                                                        <Grid container item xs={9}>
                                                            <TextField label="Search Keyword" variant="outlined" style={{ width: '100%', backgroundColor: '#fff' }} required onChange={handleChangeVideos} />
                                                        </Grid>
                                                        <Grid container item xs={3}>
                                                            <Stack direction="row" spacing={2}>
                                                                <Button variant="contained" endIcon={<SendIcon />} onClick={handleSearch}>
                                                                    Send
                                                                </Button>
                                                            </Stack>
                                                        </Grid>
                                                    </Grid>

                                                    {loading1 ? <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" style={{ minHeight: '50vh' }} >
                                                        <Grid item xs={12}>
                                                            <CirclesWithBar color="#4868ff" ariaLabel="falling-lines-loading" />
                                                        </Grid> </Grid> : null}


                                                    <Box mt={2} className="mainvideosBox">
                                                        <Grid container spacing={2}>
                                                            {videos.map((video) => (
                                                                <Grid key={video.id} item xs={4}>
                                                                    <Box>
                                                                        <video controls
                                                                            onClick={() => setSelectedVideo(video)}
                                                                            style={{ border: selectedVideo === video ? "3px solid #4868ff" : "none" }}
                                                                        >
                                                                            <source src={video?.video_files?.[1]?.link || video.videos.large.url} type="video/mp4" />
                                                                        </video>
                                                                    </Box>
                                                                </Grid>
                                                            ))}
                                                        </Grid>
                                                    </Box>


                                                </DialogContent>
                                                <DialogActions>
                                                    <Button onClick={handleClose2}>Close</Button>
                                                </DialogActions>
                                            </Dialog>

                                        </Grid>

                                        <Grid item xs={5}>
                                            <Button variant="outlined" onClick={handleClickOpen1} style={{ width: '100%', height: '100%' }}>
                                                Select Voice
                                            </Button>
                                            <Dialog
                                                fullWidth={fullWidth1}
                                                maxWidth={maxWidth1}
                                                open={open1}
                                                onClose={handleClose1}
                                            >

                                                <DialogContent>


                                                    <h3>Select a voice</h3>

                                                    {/* <Box className='backgroundVoice' mt={2}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Box px={1} pb={1}>
                           
                              <VoiceCard name="Rachel (american, mellow)" category="conversational" audiomp3="https://storage.googleapis.com/eleven-public-prod/premade/voices/21m00Tcm4TlvDq8ikWAM/dff5d82d-d16d-45b9-ae73-be2ad8850855.mp3" />
                              
                          </Box>
                        </Grid>
                      </Grid>
                    </Box> */}


                                                    <Box className='backgroundVoice' mt={3}>
                                                        <Grid container spacing={2}>
                                                            {voicearr.map((voice) => (
                                                                <Grid item xs={4} key={voice.voice_id}>
                                                                    <Box px={1} pb={1} onClick={() => setSelectedVoice(voice.voice_id)} className={selectedVoice === voice.voice_id ? "selected" : ""}>
                                                                        <VoiceCard
                                                                            name={voice.name}
                                                                            category={voice.category}
                                                                            audiomp3={voice.preview_url}
                                                                        />
                                                                    </Box>
                                                                </Grid>
                                                            ))}
                                                        </Grid>
                                                    </Box>


                                                </DialogContent>
                                                <DialogActions>
                                                    <Button onClick={handleClose1}>Close</Button>
                                                </DialogActions>
                                            </Dialog>

                                        </Grid>
                                        <Grid item xs={2}>
                                            <Button variant="contained" style={{ width: '100%', height: '100%' }} onClick={handleFinalSubmit}>Start</Button>
                                        </Grid>

                                    </Grid>
                                </Box>

                            </Box>
                        )}

                        {loading2 ? <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" style={{ minHeight: '50vh' }} >
                            <Grid item xs={12}>
                                <CirclesWithBar color="#4868ff" ariaLabel="falling-lines-loading" />
                            </Grid> </Grid> : null}


                        {messageerror.length > 0 ? (
                            <Box mt={5} display="flex" justifyContent="center">
                                <Box className='errorHeading'> {messageerror}</Box>
                            </Box>
                        ) : null
                        }


                        {finalVideoDOne.length > 0 ? (
                            <>
                                <Box>
                                    <Box mt={3} display="flex" justifyContent="center">

                                        <Box className='mainFullVideo'>

                                            <video controls className='mainvideo'>
                                                <source src={finalVideoDOne} type="video/mp4" width='100%' height='100%' />
                                            </video>

                                        </Box>


                                    </Box>
                                    <Box display="flex" justifyContent="center" mt={2} pb={6}>
                                        <Button
                                            variant="contained"
                                            href={finalVideoDOne}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            download
                                        >
                                            Download Video
                                        </Button></Box>



                                </Box>

                            </>) : null}


                            {/* {youtubeVideoUploaded.length > 0 ? (
  <Typography variant="h6"  mt={3}>
      Video has been published successfully! Here's the link:{' '}
      <Link href={youtubeVideoUploaded} target="_blank" rel="noopener">
        {youtubeVideoUploaded}
      </Link>
    </Typography>

) : null} */}


{/* {loading3 ? <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" style={{ minHeight: '50vh' }} >
            <Grid item xs={12}>
              <CirclesWithBar color="#4868ff" ariaLabel="falling-lines-loading" />
            </Grid> </Grid> : null}

            {messageerror3.length > 0 ? (
            <Box mt={5} display="flex" justifyContent="center">
              <Box className='errorHeading'> {messageerror3}</Box>
            </Box>
          ) : null
          } */}


{/* {youtubeVideos[0].title && youtubeVideos[0].description && youtubeVideos[0].tags && youtubeVideos[0].video && (
  <Box mt={5} display="flex" justifyContent="center">
  <Button
    onClick={downloadExcel}
    variant="contained"
    style={{ height: '100%', width: '50%' }}
  >
    Download Excel File With Titles, Descriptions, Tags and Video URL
  </Button>
  </Box>
)} */}

{youtubeVideos.some(video => video.title && video.description && video.tags && video.video) && (
  <Box mt={2} display="flex" justifyContent="center">
  <Button
    onClick={downloadExcel}
    variant="contained"
    style={{ height: '100%', width: '50%' }}
  >
    Download Excel File With Titles, Descriptions, Tags and Video URL
  </Button>
  </Box>
)}



                    </Container></Box></Box>

        </>
    )
}

export default SixAutoMated