import React, { useState, useEffect, } from 'react'
import { Box, Container, Grid, TextField, Button } from '@mui/material'
import axios from "axios"
import useLocalStorage from 'use-local-storage';
import { CirclesWithBar } from 'react-loader-spinner';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';


function UploadShortAudioVideo() {

  const [prompt, setPrompt] = useState('');
  const [finalprompt, setFinalPrompt] = useState('');
  const [finalVideoDOne, setfinalVideoDOne] = useState("");
  // const [backendPrompt, setSackendPrompt] = useState('');
  const [openAiApi, setOpenAiApi] = useLocalStorage('openai', '');
  const [loading, setLoading] = useState(false)
  const [loading1, setLoading1] = useState(false)
  const [loading2, setLoading2] = useState(false)
  const [messageerror, setMessageError] = useState("");
  const [video, setVideo] = useState(null);
  const [audio, setAudio] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [conversationHistory, setConversationHistory] = useState([]);
  // console.log(backendPrompt, "backendPrompt")



  // console.log(selectedVideo?.video_files[3].link)
  // console.log(selectedVoice, "selectedVoice")

  // const handleSubmit = () => {


  //   if (prompt.length < 4) {
  //     console.log('Prompt length must be at least 4 characters.');
  //     return;
  //   }

  //   if (openAiApi.length < 4) {
  //     console.log('OpenAi Api length must be at least 4 characters.');
  //     return;
  //   }

  //   setLoading(true)

  //   axios.post('https://copyvid-drsr2tzggq-nn.a.run.app/chat', { message: prompt, openAiApi })
  //     .then(response => {
  //       console.log(response);
  //       // Handle success response
  //       // setSackendPrompt(response.data.respo.text)
  //       setFinalPrompt(response.data.respo.text)
  //       setLoading(false)
  //     })
  //     .catch(error => {
  //       console.error(error);
  //       // Handle error response
  //       setLoading(false)
  //     });
  // };

  const handleSubmit = () => {
    if (prompt.length < 4) {
      console.log('Message length must be at least 4 characters.');
      return;
    }

    if (openAiApi.length < 4) {
      console.log('OpenAi Api length must be at least 4 characters.');
      return;
    }

    setLoading(true);

    axios
      .post('https://copyvid-drsr2tzggq-nn.a.run.app/chat', {
        message: prompt,
        openAiApi: openAiApi,
        conversationHistory: conversationHistory,
      })
      .then((response) => {
        console.log(response);
        // Handle success response
        const aiResponse = response.data.message;

        // Update conversation history
        setConversationHistory([
          ...conversationHistory,
          { role: 'user', message: prompt },
          { role: 'assistant', message: aiResponse },
        ]);

        // Clear the input field
        // setMessage('');

        // Update response display
        setFinalPrompt(aiResponse);

        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        // Handle error response
        // setResponse('Error: Unable to get a response from the assistant.');
        setLoading(false);
      });
  };


  useEffect(() => {
    console.log(finalprompt);
  }, [finalprompt]);



  const handleAudioChange = (e) => {
    setAudio(e.target.files[0]);
  };

  const handleVideoChange = (e) => {
    setVideo(e.target.files[0]);
  };

  const handleCloseSnackbar = () => {
    setErrorMessage(null);
  };


//   const submitHandle = async (e) => {
//     e.preventDefault();

//     if (!audio || !video) {
//       setErrorMessage('Please upload audio and video files');
//       return;
//     }

//     setLoading2(true);
//     const formData = new FormData();
//     formData.append('audio', audio);
//     formData.append('video', video);

//     try {
//       const response = await fetch('https://copyvid-drsr2tzggq-nn.a.run.app/uploadaudioandfullvideo', {
//         method: 'POST',
//         body: formData,
//       });
// console.log(response, "response")
//       setfinalVideoDOne(response.data.url)

//       // Process response data here as needed
//     } catch (error) {
//       console.log(error ,"error")
//       setMessageError(`Error ${error.response.status}: ${error.response.data.error}`)
//     } finally {
//       setLoading2(false);
//     }
//   };

  const submitHandle = async (e) => {
    e.preventDefault();
  
    if (!audio || !video) {
      setErrorMessage('Please upload audio and video files');
      return;
    }
  
    setLoading2(true);
    const formData = new FormData();
    formData.append('audio', audio);
    formData.append('video', video);
  
    try {
      const response = await axios.post('https://copyvid-drsr2tzggq-nn.a.run.app/uploadshortaudioandvideo', formData);
      console.log(response, "response 123");
      setfinalVideoDOne(response.data.url);
      setLoading2(false);
    } catch (error) {
      console.log(error, "error 123");
      setMessageError(`Error ${error.response.status}: ${error.response.data.error}`);
      setLoading2(false);
    } finally {
      setLoading2(false);
    }
  };
  



  return (
    <Box className="mainBox" mt={2}>
      <Box pb={5}>
        <Container maxWidth="xl">

          <Box my={1} >
            <Grid container spacing={2}>
              <Grid item xs={10}>
                <TextField label="Prompt" variant="outlined" multiline rows={3} style={{ width: '100%', }} value={prompt} onChange={(event) => setPrompt(event.target.value)} required />
              </Grid>
              <Grid item xs={2}>
                <TextField label="OpenAi Api" variant="outlined" style={{ width: '100%', marginBottom: '5px', }} value={openAiApi} onChange={(event) => setOpenAiApi(event.target.value)} required />

                <Button variant="contained" style={{ width: '100%', height: '40%' }} onClick={handleSubmit}>Submit</Button>
              </Grid>

            </Grid>
          </Box>

          {loading ? <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" style={{ minHeight: '50vh' }} >
            <Grid item xs={12}>
              <CirclesWithBar color="#4868ff" ariaLabel="falling-lines-loading" />
            </Grid> </Grid> : null}

          <Box style={{ width: '100%', }} mt={3}>
            {/* <Box style={{ width: '100%', backgroundColor: '#fff', minHeight: '200px' }} p={1} mt={3}> */}
            {/* <PlainTextExample /> */}
            <TextField
              id="outlined-multiline-flexible" style={{ width: '100%' }}
              label=""
              multiline
              value={finalprompt}
              onChange={(e) => setFinalPrompt(e.target.value)}
              rows={8}
            // maxRows={14}
            />
          </Box>




          {/* // ) : // {finalprompt.length > 2 ? (  null}  */}

          {/* <Box mt={2}>

            <Grid container spacing={2}>
              <Grid item xs={5}>
              <Button variant="contained" component="label" style={{ width: '100%'}}>
                             Upload Audio
                        <input hidden accept=".mp3"  multiple type="file" onChange={(e) => setAudio(e.target.files[0])} />
                    </Button>
              </Grid>
              <Grid item xs={5}>
              <Button variant="contained" component="label" style={{ width: '100%'}}>
                             Upload Video
                        <input hidden accept=".mp4" multiple type="file" onChange={(e) => setVideo(e.target.files[0])} />
                    </Button>
              </Grid>
              <Grid item xs={2}>
              <Button variant="contained" type="submit" className="buttonSubmit" style={{ width: '100%'}} >
                            Submit
                    </Button>
              </Grid>
            </Grid>

          </Box> */}

          <Box mt={2} component="form" onSubmit={submitHandle}>

            <Grid container spacing={2}>
              <Grid item xs={5}>
                <Button
                  variant="contained"
                  component="label"
                  style={{ width: '100%' }}
                >
                  Upload Audio
                  <input
                    hidden
                    accept=".mp3"
                    multiple
                    type="file"
                    onChange={handleAudioChange}
                  />
                </Button>
              </Grid>
              <Grid item xs={5}>
                <Button
                  variant="contained"
                  component="label"
                  style={{ width: '100%' }}
                >
                  Upload Video
                  <input
                    hidden
                    accept=".mp4"
                    multiple
                    type="file"
                    onChange={handleVideoChange}
                  />
                </Button>
              </Grid>
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  type="submit"
                  className="buttonSubmit"
                  style={{ width: '100%' }}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>

            <Snackbar
              open={!!errorMessage}
              autoHideDuration={6000}
              onClose={handleCloseSnackbar}
            >
              <Alert onClose={handleCloseSnackbar} severity="error">
                {errorMessage}
              </Alert>
            </Snackbar>

          </Box>

          {loading2 ? <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" style={{ minHeight: '50vh' }} >
            <Grid item xs={12}>
              <CirclesWithBar color="#4868ff" ariaLabel="falling-lines-loading" />
            </Grid> </Grid> : null}


          {messageerror.length > 0 ? (
            <Box mt={5} display="flex" justifyContent="center">
              <Box className='errorHeading'> {messageerror}</Box>
            </Box>
          ) : null
          }




          {finalVideoDOne.length > 0 ? (
            <Box>
              <Box mt={3} display="flex" justifyContent="center">

                <Box className='shortsVideoBox'>


                  <video controls className='mainvideo'>
                    <source src={finalVideoDOne} type="video/mp4" width='100%' height='100%' />
                  </video>

                </Box>


              </Box>
              <Box display="flex" justifyContent="center" mt={2} pb={6}>
                <Button
                  variant="contained"
                  href={finalVideoDOne}
                  target="_blank"
                  rel="noopener noreferrer"
                  download
                >
                  Download Video
                </Button></Box>

              <Box mt={1} display="flex" justifyContent="center" pb={6}>
                <Box className='mainHeadingbox'> To create new video, please refresh page and submit again</Box>
              </Box>


            </Box>

          ) : null}


        </Container>
      </Box>
    </Box>
  )
}



export default UploadShortAudioVideo