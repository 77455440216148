import React, { useState, useRef, useEffect  } from 'react'
import { Box, DialogContentText, Typography, DialogTitle, Tab, Link, Tabs, Stack, Alert, Snackbar, Container, Grid, TextField, Button, Dialog, DialogActions, DialogContent, } from '@mui/material'
import { CirclesWithBar } from 'react-loader-spinner';
import axios from 'axios';
import useLocalStorage from 'use-local-storage';
// import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import AudioPlayer from 'material-ui-audio-player';
import VoiceCard from "../Component/VoiceCard"
import CheckMarksSettings from "../Component/CheckMarksSettings"

// const muiTheme = createMuiTheme({});
// const ad = "http://res.cloudinary.com/dj3vkkpjf/video/upload/v1683398531/rjg7dkqydx5z0jzbppd2.mp3"


function SlideVids() {


    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [elevenLabsApi, setelevenLabsApi] = useLocalStorage('elevenlab', '');
    const [loading, setLoading] = useState(false);
    const [loading1, setLoading1] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [openAiError, setOpenAiError] = useState('');
    const [openAiError1, setOpenAiError1] = useState('');
    const [openAiApi, setOpenAiApi] = useLocalStorage('openai', '');
    const [prompt, setPrompt] = useState('');
    const [conversationHistory, setConversationHistory] = useState([]);
    const [alertSeverity, setAlertSeverity] = useState('success');
    const [finalprompt, setFinalPrompt] = useState('');
    const [mp3AudioFile, setMp3AudioFile] = useState('');
    // const [mp3AudioFile, setMp3AudioFile] = useState('http://res.cloudinary.com/dj3vkkpjf/video/upload/v1683489124/v0c3ryepnwrqpim7amxg.mp3');
    const [selectedVoice, setSelectedVoice] = useState(null);
    const [fullWidth1, setFullWidth1] = useState(true);
    const [maxWidth1, setMaxWidth1] = useState('md');
    const [textPosition, setTextPosition] = useState(['center', 'bottom']);
    const [open1, setOpen1] = useState(false);
    // console.log(selectedVoice, "selectedVoice 12345")
    const [selectedNames, setSelectedNames] = useState([]);
    const [open, setOpen] = useState(false);
    const [fullWidth, setFullWidth] = useState(true);
    const [maxWidth, setMaxWidth] = useState('xl');
    const [value, setValue] = React.useState(0);



    const [keywordPixabay, setKeywordPixabay] = useState('');
    const [imagesPixabay, setImagesPixabay] = useState([]);
    const [loadingPixabay, setLoadingPixabay] = useState(false);
    const [pixabayError, setPixabayError] = useState('');


    const [keywordPexels, setKeywordPexels] = useState('');
    const [imagesPexels, setImagesPexels] = useState([]);
    const [loadingPexels, setLoadingPexels] = useState(false);
    const [pexelsError, setPexelsError] = useState('');



    const [selectedImages, setSelectedImages] = useState([]);

    console.log(selectedImages, "selectedImages")


    const [finalLoading, setFinalLoading] = useState(false);
    const [finalError, setFinalError] = useState("");
    const [finalVideo, setFinalVideo] = useState("");



    const [openSnackbar, setOpenSnackbar] = useState(false);
const [snackbarMessage, setSnackbarMessage] = useState("");


    const [inputKey, setInputKey] = useState(Date.now());
    const [inputKeypexels, setInputKeypexels] = useState(Date.now());
  const inputRef = useRef();
  const inputRefpexels = useRef();

  const handleChange = (e) => {
    setKeywordPixabay(e.target.value);
    setInputKey(Date.now());
  };
  const handleChangepexels = (e) => {
    setKeywordPexels(e.target.value);
    setInputKeypexels(Date.now());
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputKey]);

  useEffect(() => {
    if (inputRefpexels.current) {
      inputRefpexels.current.focus();
    }
  }, [inputKeypexels]);


    const handleImageClick = (largeImageURL) => {
        if (selectedImages.includes(largeImageURL)) {
            setSelectedImages(selectedImages.filter((img) => img !== largeImageURL));
        } else {
            setSelectedImages([...selectedImages, largeImageURL]);
        }
    };


  

    const handleImageClickPexels = (originalImageURL) => {
        if (selectedImages.includes(originalImageURL)) {
          setSelectedImages(selectedImages.filter((img) => img !== originalImageURL));
        } else {
          setSelectedImages([...selectedImages, originalImageURL]);
        }
      };
      

    // console.log(textPosition, "87")
    const handleSelectedNames = (names) => {
        setSelectedNames(names);
    };

    const handleSubmit = async () => {

        const fields = [
            // { value: clientId, label: 'Client ID' },
            // { value: clientSecret, label: 'Client Secret' },
            // { value: accessCode, label: 'Access Code' },
            { value: openAiApi, label: 'OpenAI API' },
            { value: prompt, label: 'Prompt' },
            { value: elevenLabsApi, label: 'Eleven Labs Api' },
        ];

        const emptyField = fields.find(field => !field.value);

        if (emptyField) {
            setAlertMessage(`Please add ${emptyField.label}`);
            setAlertSeverity('error');
            setSnackbarOpen(true);
        } else {

            setLoading(true);

            axios
                .post('https://copyvid-drsr2tzggq-nn.a.run.app/chat', {
                    message: prompt,
                    openAiApi: openAiApi,
                    conversationHistory: conversationHistory,
                })
                .then((response) => {
                    console.log(response);
                    // Handle success response

                    const aiResponse = response.data.message;

                    // Update conversation history
                    setConversationHistory([
                        ...conversationHistory,
                        { role: 'user', message: prompt },
                        { role: 'assistant', message: aiResponse },
                    ]);


                    setFinalPrompt(aiResponse);

                    setLoading(false);
                })
                .catch((error) => {
                    console.error(error);
                    setOpenAiError(`Error ${error.response.status}: ${error.response.data.error}`);
                    setLoading(false);
                });
        }
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const voicearr = [
        {
            "voice_id": "21m00Tcm4TlvDq8ikWAM",
            "name": "Rachel (american, mellow)",
            "voice_name": "Rachel",
            "samples": [],
            "category": "conversational",
            "preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/21m00Tcm4TlvDq8ikWAM/dff5d82d-d16d-45b9-ae73-be2ad8850855.mp3",
            "available_for_tiers": [],
            "settings": null
        },
        {
            "voice_id": "AZnzlk1XvdvUeBnXmlld",
            "name": "Domi (american, engaged)",
            "voice_name": "Domi",
            "samples": [],
            "category": "dynamic",
            "preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/AZnzlk1XvdvUeBnXmlld/53bd2f5f-bb59-4146-9922-245b2a466c80.mp3",
            "available_for_tiers": [],
            "settings": null
        },
        {
            "voice_id": "EXAVITQu4vr4xnSDxMaL",
            "name": "Bella (American, soft)",
            "voice_name": "Bella",
            "samples": [],
            "category": "conversational",
            "preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/EXAVITQu4vr4xnSDxMaL/53bd2f5f-bb59-4146-8822-245b2a466c80.mp3",
            "available_for_tiers": [],
            "settings": null
        },
        {
            "voice_id": "ErXwobaYiN019PkySvjV",
            "name": "Antoni (American, modulated)",
            "voice_name": "Antoni",
            "samples": [],
            "category": "conversational",
            "preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/ErXwobaYiN019PkySvjV/53bd2f5f-bb59-1111-8822-225b2a466c80.mp3",
            "available_for_tiers": [],
            "settings": null
        },
        {
            "voice_id": "MF3mGyEYCl7XYWbV9V6O",
            "name": "Elli (american, clear)",
            "voice_name": "Elli",
            "samples": [],
            "category": "dynamic",
            "preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/MF3mGyEYCl7XYWbV9V6O/bea2dc16-9abf-4162-b011-66531458e022.mp3",
            "available_for_tiers": [],
            "settings": null
        },
        {
            "voice_id": "TxGEqnHWrfWFTfGW9XjX",
            "name": "Josh (american, silvery)",
            "voice_name": "Josh",
            "samples": [],
            "category": "conversational",
            "preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/TxGEqnHWrfWFTfGW9XjX/bdc4303c-a20d-4cec-97eb-dca625044eac.mp3",
            "available_for_tiers": [],
            "settings": null
        },
        {
            "voice_id": "VR6AewLTigWG4xSOukaG",
            "name": "Arnold (american, nasal)",
            "voice_name": "Arnold",
            "samples": [],
            "category": "conversational",
            "preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/VR6AewLTigWG4xSOukaG/2c4395e7-91b1-44cd-8f0f-e4aebd292461.mp3",
            "available_for_tiers": [],
            "settings": null
        },
        {
            "voice_id": "pNInz6obpgDQGcFmaJgB",
            "name": "Adam (American, clear)",
            "voice_name": "Adam",
            "samples": [],
            "category": "conversational",
            "preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/pNInz6obpgDQGcFmaJgB/53bd2f5f-bb59-1111-8822-245b2a466c80.mp3",
            "available_for_tiers": [],
            "settings": null
        },
        {
            "voice_id": "yoZ06aMxZJJ28mfd3POQ",
            "name": "Sam (american, dynamic)",
            "voice_name": "Sam",
            "samples": [],
            "category": "dynamic",
            "preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/yoZ06aMxZJJ28mfd3POQ/1c4d417c-ba80-4de8-874a-a1c57987ea63.mp3",
            "available_for_tiers": [],
            "settings": null
        }
    ]

    const handleClickOpen1 = () => {
        setOpen1(true);
    };

    const handleClose1 = () => {
        setOpen1(false);
    };

    const handleSubmitAudio = async () => {

        const fields = [

            { value: selectedVoice, label: 'Voice' },
        ];

        const emptyField = fields.find(field => !field.value);

        if (emptyField) {
            setAlertMessage(`Please add ${emptyField.label}`);
            setAlertSeverity('error');
            setSnackbarOpen(true);
            return;
        }

        setLoading1(true);

        const requestBody = {
            api_key: elevenLabsApi,
            text: finalprompt,
            voice: selectedVoice,
        };

        try {
            const response = await axios.post('https://editlycopyvid.herokuapp.com/audio', requestBody);
            console.log(response.data); // Use the response data as needed
            setMp3AudioFile(response.data.url);
            setLoading1(false);
        } catch (error) {
            setLoading1(false);
            setOpenAiError1(`Error ${error.response.status}: ${error.response.data.error}`);
        }
    };


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleCloseImages = () => {
        setOpen(false);
    };

    const handleMaxWidthChange = (event) => {
        setMaxWidth(
            // @ts-expect-error autofill of arbitrary value is not handled.
            event.target.value,
        );
    };

    const handleFullWidthChange = (event) => {
        setFullWidth(event.target.checked);
    };


    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }
    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }



    const handleChangeTablet = (event, newValue) => {
        setValue(newValue);
    };


    const handleChangePixabay = (event) => {
        setKeywordPixabay(event.target.value);
    };

    const handleSubmitPixabay = async () => {
        setLoadingPixabay(true);
        setPixabayError('');
        try {
            const response = await axios.get('https://pixabay.com/api/', {
                params: {
                    key: '36175074-d495987a3f73cee9599e3e670',
                    q: keywordPixabay,
                    per_page: 20,
                    webformatURL: '_960',
                    min_width: 1200,
                    // max_width: 1200,
                    // min_height: 720,
                },
            });

            console.log(response.data)

            setImagesPixabay(response.data.hits);
        } catch (error) {
            setPixabayError(`Error ${error.response.status}: ${error.response.data.error}`);
        } finally {
            setLoadingPixabay(false);
        }
    };


    const handleSubmitPexels = async () => {
        setLoadingPexels(true);
        setPexelsError('');
        try {
          const response = await axios.get('https://api.pexels.com/v1/search', {
            headers: {
              Authorization: 'rDy1VOIa3zC5uVej0vJ2T5G5hnhmpALRI89fQWpiWuse7rHQHFGKmrWi'
            },
            params: {
              query: keywordPexels,
              per_page: 70,
            }
          });
          console.log(response.data);
          setImagesPexels(response.data.photos);
        } catch (error) {
          setPexelsError(`Error ${error.response.status}: ${error.response.data.error}`);
        } finally {
          setLoadingPexels(false);
        }
      };
      



    //   const [imageDetails, setImageDetails] = useState([]);

    //   useEffect(() => {
    //     const initialImageDetails = selectedImages.map(image => ({
    //         url: image,
    //         text: '',
    //         color: ''
    //     }));

    //     setImageDetails(initialImageDetails);
    // }, [selectedImages]);

    // const handleInputChange = (index, field, value) => {
    //     const newImageDetails = [...imageDetails];
    //     newImageDetails[index][field] = ['duration', 'fontSize'].includes(field) ? Number(value) : value;
    //     setImageDetails(newImageDetails);
    // }

    // console.log(imageDetails, "imageDetails 123")

    // const handleFinalCreateVideo = async () => {
    //     setFinalLoading(true);
    //     try {
    //         const response = await axios.post('https://flask-docker-i4eamunfda-uc.a.run.app/sizeupdated', {
    //             images: imageDetails,
    //             position: ["center", "bottom"],
    //             audio_url: {
    //                 url: mp3AudioFile
    //             }
    //         });

    //         console.log(response.data);
    //         setFinalVideo(response.data.url)
    //         setFinalLoading(false);
    //     } catch (error) {
    //         setFinalError(`Error ${error.response.status}: ${error.response.data.error}`);
    //         setFinalLoading(false);
    //     }
    // }


    const [imageDetails, setImageDetails] = useState([]);

useEffect(() => {
    const initialImageDetails = selectedImages.map(image => {
        let imageDetail = {
            url: image,
            duration: 0 // default duration
        }

        if (selectedNames.includes("Text")) {
            imageDetail = {
                ...imageDetail,
                text: '',
                fontSize: 0,
                color: ''
            }
        }

        return imageDetail;
    });

    setImageDetails(initialImageDetails);
}, [selectedImages, selectedNames]);

const handleInputChange = (index, field, value) => {
    const newImageDetails = [...imageDetails];
    newImageDetails[index][field] = ['duration', 'fontSize'].includes(field) ? Number(value) : value;
    setImageDetails(newImageDetails);
}

console.log(imageDetails, "imageDetails 123")


const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
  
    setOpenSnackbar(false);
  };


  const validateInputs = () => {
    for (let i = 0; i < imageDetails.length; i++) {
      const detail = imageDetails[i];
      if (selectedNames.includes("Text")) {
        if (!detail.text || !detail.color || !detail.fontSize || detail.fontSize === 0) {
          setSnackbarMessage(`Please fill all fields for image ${i + 1}`);
          return false;
        }
      }
      if (!detail.duration || detail.duration === 0) {
        setSnackbarMessage(`Please provide a non-zero duration for image ${i + 1}`);
        return false;
      }
    }
    return true;
  };
  

  const handleFinalCreateVideo = async () => {
    if (!validateInputs()) {
      setOpenSnackbar(true);
      return;
    }
    setFinalLoading(true);
    setFinalVideo("")
    try {
      const response = await axios.post('https://flask-docker-i4eamunfda-uc.a.run.app', {
          images: imageDetails,
          position: textPosition,
          audio_url: {
              url: mp3AudioFile
          }
      });
  
      console.log(response.data);
      setFinalVideo(response.data.url)
      setFinalLoading(false);
      setFinalError("")
    } catch (error) {
      setFinalError(`Error ${error.response.status}: ${error.response.data.error}`);
      setFinalLoading(false);
    }
  };
  






    return (
        <>
            <Box className="mainBox" mt={2}>
                <Box pb={5}>
                    <Container maxWidth="xl">

                        <Box className='firstBox'>
                            <Grid container spacing={1} pt={3}>
                                {/* <Grid item xs={3}>
                <TextField label="Client ID" value={clientId} onChange={(e) => setClientId(e.target.value)} fullWidth />
            </Grid> */}
                                {/* <Grid item xs={3}>
                <TextField label="Client Secret" value={clientSecret} onChange={(e) => setClientSecret(e.target.value)} fullWidth />
            </Grid> */}
                                {/* <Grid item xs={2}>
                <TextField label="Access Code" value={accessCode} onChange={(e) => setAccessCode(e.target.value)} fullWidth />
            </Grid> */}



                                <Grid item xs={3.5}>
                                    <TextField label="Prompt" value={prompt} onChange={(e) => setPrompt(e.target.value)} fullWidth />
                                </Grid>
                                <Grid item xs={1.5}>
                                    <TextField label="OpenAI API" value={openAiApi} onChange={(e) => setOpenAiApi(e.target.value)} fullWidth />
                                </Grid>
                                <Grid item xs={1.5}>
                                    <TextField label="ElevenLabs Api" variant="outlined" style={{ width: '100%', }} value={elevenLabsApi} onChange={(event) => setelevenLabsApi(event.target.value)} required />
                                </Grid>

                                <Grid item xs={1.5}>
                                    <Button variant="outlined" onClick={handleClickOpen1} style={{ width: '100%', height: '100%' }}>
                                        Select Voice
                                    </Button>
                                    <Dialog
                                        fullWidth={fullWidth1}
                                        maxWidth={maxWidth1}
                                        open={open1}
                                        onClose={handleClose1}
                                    >

                                        <DialogContent>


                                            <h3>Select a voice</h3>

                                            {/* <Box className='backgroundVoice' mt={2}>
    <Grid container spacing={2}>
    <Grid item xs={6}>
        <Box px={1} pb={1}>
        
            <VoiceCard name="Rachel (american, mellow)" category="conversational" audiomp3="https://storage.googleapis.com/eleven-public-prod/premade/voices/21m00Tcm4TlvDq8ikWAM/dff5d82d-d16d-45b9-ae73-be2ad8850855.mp3" />
            
        </Box>
    </Grid>
    </Grid>
</Box> */}


                                            <Box className='backgroundVoice' mt={3}>
                                                <Grid container spacing={2}>
                                                    {voicearr.map((voice) => (
                                                        <Grid item xs={4} key={voice.voice_id}>
                                                            <Box px={1} pb={1} onClick={() => setSelectedVoice(voice.voice_name)} className={selectedVoice === voice.voice_name ? "selected" : ""}>
                                                                <VoiceCard
                                                                    name={voice.name}
                                                                    category={voice.category}
                                                                    audiomp3={voice.preview_url}
                                                                />
                                                            </Box>
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                            </Box>


                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={handleClose1}>Close</Button>
                                        </DialogActions>
                                    </Dialog>

                                </Grid>

                                <Grid item xs={2}>
                                    <Button onClick={handleSubmit} variant="contained" style={{ height: "100%", width: "100%" }}>Generate Script</Button>
                                </Grid>
                                <Grid item xs={2}>
                                    <Button onClick={handleSubmitAudio} variant="contained" style={{ height: "100%", width: "100%" }}>
                                        Create Audio
                                    </Button>
                                </Grid>

                                {/* <Grid item xs={2}>
                <Button onClick={downloadExcel} variant="contained" style={{ height: "100%", width: "100%" }}>
                    Start
                </Button>
            </Grid> */}

    </Grid>
    <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={alertSeverity} sx={{ width: '100%' }}>
            {alertMessage}
        </Alert>
    </Snackbar>
</Box>

{loading ? <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" style={{ minHeight: '50vh' }} >
    <Grid item xs={12}>
        <CirclesWithBar color="#4868ff" ariaLabel="falling-lines-loading" />
    </Grid> </Grid> : null}


<Box style={{ width: '100%', }} mt={3}>

    <TextField
        id="outlined-multiline-flexible" style={{ width: '100%' }}
        label="Video Script"
        multiline
        value={finalprompt}
        onChange={(e) => setFinalPrompt(e.target.value)}
        rows={10}

    />
</Box>

{openAiError.length > 0 ? (
    <Box mt={5} display="flex" justifyContent="center">
        <Box className='errorHeading'> {openAiError}</Box>
    </Box>
) : null
}


{loading1 ? <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" style={{ minHeight: '33vh' }} >
    <Grid item xs={12}>
        <CirclesWithBar color="#4868ff" ariaLabel="falling-lines-loading" />
    </Grid> </Grid> : null}

{openAiError1.length > 0 ? (
    <Box mt={5} display="flex" justifyContent="center">
        <Box className='errorHeading'> {openAiError1}</Box>
    </Box>
) : null
}





{/* {selectedImages.length > 0 && 
                <Box mt={3}>
                    <Grid container spacing={1}>
                        {selectedImages.map((image, index) => (
                            <Grid item xs={3} key={index}>
                                <img src={image} alt={`Selected ${index}`} style={{width: '100%'}}/>
                                <Box mb={1}>
                                    <TextField 
                                        label="Text" 
                                        fullWidth 
                                        value={imageDetails[index]?.text}
                                        onChange={(e) => handleInputChange(index, 'text', e.target.value)}
                                    /> 
                                </Box>
                                <Grid container spacing={1}>
                                    <Grid item xs={4}>
                                        <TextField 
                                            label="Duration" 
                                            fullWidth 
                                            value={imageDetails[index]?.duration}
                                            onChange={(e) => handleInputChange(index, 'duration', e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField 
                                            label="Font Size" 
                                            fullWidth 
                                            value={imageDetails[index]?.fontSize}
                                            onChange={(e) => handleInputChange(index, 'fontSize', e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField 
                                            label="Colour" 
                                            fullWidth 
                                            value={imageDetails[index]?.color}
                                            onChange={(e) => handleInputChange(index, 'color', e.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            } */}


{selectedImages.length > 0 && 
    <Box mt={3}>
        <Grid container spacing={1}>
            {selectedImages.map((image, index) => (
                <Grid item xs={3} key={index}>
                    <img src={image} alt={`Selected ${index}`} style={{width: '100%'}}/>
                    <Box mb={1}>
                        {selectedNames.includes("Text") && (
                            <TextField 
                                label="Text" 
                                fullWidth 
                                value={imageDetails[index]?.text}
                                onChange={(e) => handleInputChange(index, 'text', e.target.value)}
                            /> 
                        )}
                    </Box>
                    <Grid container spacing={1}>
                        <Grid item xs={4}>
                            <TextField 
                                label="Duration" 
                                fullWidth 
                                value={imageDetails[index]?.duration}
                                onChange={(e) => handleInputChange(index, 'duration', e.target.value)}
                            />
                        </Grid>
                        {selectedNames.includes("Text") && (
                            <>
                                <Grid item xs={4}>
                                    <TextField 
                                        label="Font Size" 
                                        fullWidth 
                                        value={imageDetails[index]?.fontSize}
                                        onChange={(e) => handleInputChange(index, 'fontSize', e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField 
                                        label="Colour" 
                                        fullWidth 
                                        value={imageDetails[index]?.color}
                                        onChange={(e) => handleInputChange(index, 'color', e.target.value)}
                                    />
                                </Grid>
                            </>
                        )}
                    </Grid>
                </Grid>
            ))}
        </Grid>
    </Box>
}





{mp3AudioFile.length > 10 ? (
    <Box mt={4}>
        <Grid container spacing={1}>
            <Grid item xs={selectedNames.includes("Text Position") ? 5 : 6}>
                <Box pl={1} className="checkmarksettings">
                    <AudioPlayer
                        elevation={1}
                        height="57px"
                        width="100%"
                        variation="primary"
                        download={true}
                        order="standart"
                        autoplay={true}
                        preload="auto"
                        loop={true}
                        src={mp3AudioFile}
                    />
                </Box>
            </Grid>
            <Grid item xs={2}>
                <CheckMarksSettings onSelectedNamesChange={handleSelectedNames} />
            </Grid>

            {selectedNames.includes("Text Position") && (
                <Grid item xs={1.5}>
                 <TextField label="Text Position" value={textPosition.join(', ')} onChange={(e) => setTextPosition(e.target.value.split(",").map(item => item.trim()))} fullWidth />
                </Grid>
            )}

            <Grid item xs={2}>
                <Box style={{ width: '100%', height: '100%' }}>
                    <Button variant="outlined" onClick={handleClickOpen} style={{ width: '100%', height: '100%' }} >
                        Select images
                    </Button>
                    <Dialog
                        fullWidth={fullWidth}
                        maxWidth={maxWidth}
                        open={open}
                        onClose={handleCloseImages}
                    >
                        {/* <DialogTitle>Optional sizes</DialogTitle> */}
                        <DialogContent>
                            <DialogContentText>

                                <Box sx={{ width: '100%', backgroundColor: '#f2f5f6' }} mt={2}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <Tabs value={value} onChange={handleChangeTablet} aria-label="basic tabs example" centered>
                                            <Tab label="Pixabay" {...a11yProps(0)} />
                                            <Tab label="Pexels" {...a11yProps(1)} />
                                            <Tab label="Upslash" {...a11yProps(2)} />
                                        </Tabs>
                                    </Box>

                                    <TabPanel value={value} index={0}>
                                        <Box>
                                            <Grid container spacing={2}>
                                                <Grid item xs={9}>
                                                <TextField
      label="Image Keyword"
      value={keywordPixabay}
      onChange={handleChange}
      fullWidth
      inputRef={inputRef}
      key={inputKey}
    />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Button variant="outlined" onClick={handleSubmitPixabay} style={{ width: '100%', height: '100%' }} >
                                                        Submit
                                                    </Button>
                                                </Grid>
                                            </Grid>

                                            {loadingPixabay ? <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" style={{ minHeight: '33vh' }} >
                                                <Grid item xs={12}>
                                                    <CirclesWithBar color="#4868ff" ariaLabel="falling-lines-loading" />
                                                </Grid> </Grid> : null}

                                            {pixabayError.length > 0 ? (
                                                <Box mt={5} display="flex" justifyContent="center">
                                                    <Box className='errorHeading'> {pixabayError}</Box>
                                                </Box>
                                            ) : null
                                            }

                        <Box mt={3}>
                            <Grid container spacing={2}>
                                {imagesPixabay.map((imagePixabay) => (
                                    <Grid item xs={3} key={imagePixabay.id}>
                                        <img
                                            src={imagePixabay.largeImageURL}
                                            alt={imagePixabay.tags}
                                            onClick={() => handleImageClick(imagePixabay.largeImageURL)}
                                            style={{
                                                width: '100%',
                                                height: 'auto',
                                                cursor: 'pointer',
                                                border: selectedImages.includes(imagePixabay.largeImageURL)
                                                    ? '4px solid #4868ff'
                                                    : 'none',
                                            }}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>

                                        </Box>
                                    </TabPanel>
                                    <TabPanel value={value} index={1}>


                                    <Box>
                                            <Grid container spacing={2}>
                                                <Grid item xs={9}>
                                                <TextField
      label="Image Keyword"
      value={keywordPexels}
      onChange={handleChangepexels}
      fullWidth
      inputRef={inputRefpexels}
      key={inputKeypexels}
    />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Button variant="outlined" onClick={handleSubmitPexels} style={{ width: '100%', height: '100%' }} >
                                                        Submit
                                                    </Button>
                                                </Grid>
                                            </Grid>

                                            {loadingPexels ? <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" style={{ minHeight: '33vh' }} >
                                                <Grid item xs={12}>
                                                    <CirclesWithBar color="#4868ff" ariaLabel="falling-lines-loading" />
                                                </Grid> </Grid> : null}

                                            {pexelsError.length > 0 ? (
                                                <Box mt={5} display="flex" justifyContent="center">
                                                    <Box className='errorHeading'> {pexelsError}</Box>
                                                </Box>
                                            ) : null
                                            }

                        <Box mt={3}>
                            <Grid container spacing={2}>
                                {imagesPexels.map((imagepexel) => (
                                    <Grid item xs={3} key={imagepexel.id}>
                                        <img
                                            src={imagepexel.src.original}
                                            alt={imagepexel.alt}
                                            onClick={() => handleImageClickPexels(imagepexel.src.original)}
                                            style={{
                                                width: '100%',
                                                height: 'auto',
                                                cursor: 'pointer',
                                                border: selectedImages.includes(imagepexel.src.original)
                                                    ? '4px solid #4868ff'
                                                    : 'none',
                                            }}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>

                                        </Box>


                                    </TabPanel>
                                    <TabPanel value={value} index={2}>

                                    </TabPanel>


                                </Box>

                            </DialogContentText>

                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseImages}>Close</Button>
                        </DialogActions>
                    </Dialog>
                </Box>

            </Grid>



            <Grid item xs={selectedNames.includes("Text Position") ? 1.5 : 2}>
                <Button
                    onClick={handleFinalCreateVideo}
                    variant="contained"
                    style={{ height: "100%", width: "100%" }}
                >
                    Create Video
                </Button>
            </Grid>
        </Grid>
    </Box>
) : null}




{finalVideo.length > 0 ? (
                            <>
                                <Box>
                                    <Box mt={3} display="flex" justifyContent="center">

                                        <Box className='mainFullVideoSlide'>

                                            <video controls className='mainvideo'>
                                                <source src={finalVideo} type="video/mp4" width='100%' height='100%' />
                                            </video>

                                        </Box>


                                    </Box>
                                    <Box display="flex" justifyContent="center" mt={2} pb={6}>
                                        <Button
                                            variant="contained"
                                            href={finalVideo}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            download
                                        >
                                            Download Video
                                        </Button></Box>



                                </Box>

                            </>) : null}


                            {finalLoading ? <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" style={{ minHeight: '33vh' }} >
    <Grid item xs={12}>
        <CirclesWithBar color="#4868ff" ariaLabel="falling-lines-loading" />
    </Grid> </Grid> : null}

{finalError.length > 0 ? (
    <Box mt={5} display="flex" justifyContent="center">
        <Box className='errorHeading'> {finalError}</Box>
    </Box>
) : null
}


<Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
  <Alert onClose={handleCloseSnackbar} severity="error">
    {snackbarMessage}
  </Alert>
</Snackbar>



                    </Container></Box></Box>

        </>
    )
}

export default SlideVids