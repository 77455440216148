import {BrowserRouter as Router, Route, Routes, Navigate , Outlet } from 'react-router-dom';
import ResponsiveBar from "./Component/ButtonAppBar";
import { createTheme, ThemeProvider } from '@mui/material/styles'; 
import { Box } from '@mui/material/';
import React, {useState, useEffect} from 'react';
// import Home from "./Pages/Home"
// import ShortsMaker from "./Pages/ShortsMaker"
import FullShortFinal from './Pages/FullShortFinal';
import TawkTo from 'tawkto-react';
import SignUp from "./Pages/SignUp"
import Login from './Pages/Login';
import Alerts from "./Component/Alerts";
import FullVideoFinal from './Pages/FullVideoFinal';
import YouTubeAuth from './Pages/YouTubeAuth';
import SlideVids from './Pages/SlideVids';



const theme = createTheme({
  palette: {
    primary: {
      main: '#4868ff',
      secondary: '#fff'
    },
  },
});

const PrivateRoutes = () => {
  const [tokens, setTokens] = useState({ token: true });

  useEffect(() => {
    const tawkans = localStorage.getItem("extension");
    if (tawkans && tawkans.length !== 0) {
      setTokens({ token: true  });
      // console.log(tawk)
     
    } else {
      setTokens({ token: false  });
     
    }
    // console.log(tawk)
    
  }, []);

  return tokens.token ? <Outlet /> : <Navigate to='/signup' />;
};



function App() {


  const [dataas, setDataas] = useState({ token: false })

  useEffect(() => {

   

    var tawk = new TawkTo('62ab69c17b967b117994ecb6', '1g5mpa8fp')
  
    tawk.onStatusChange((status) => 
    {
        // console.log(status)
    })
  
    // if (localStorage.getItem("tawk") === null) {
    //   setTokens(false);
    // } else {
    //   setTokens(true);
    // }
  
    const tawkans = localStorage.getItem("extension");
    if (tawkans && tawkans.length !== 0) {
      setDataas({ token: true });
    } else {
      setDataas({ token: false });
    }
  
  
  
  }, []);

  return (
   
    <ThemeProvider theme={theme}>
    <Router>
    <Box sx={{ display: 'flex', flexDirection: 'column',  justifyContent: 'space-between', maxHeight: 'md'  }} className="bigContainer">
    <Box>
      <ResponsiveBar tokens={dataas.token}  />
      {dataas.token && <Alerts />} 
        </Box>
        <Box sx={{ flexGrow: 1, marginBottom: 4 }}>
        <Routes>
          
        <Route element={<PrivateRoutes/>}> 
        <Route exact path="/" element={ <FullVideoFinal /> } /> 
        <Route exact path="/slidevids" element={ <SlideVids /> } /> 
        <Route  path="/shorts" element={ <FullShortFinal /> } /> 
        </Route>


        <Route  path="/login" element={ <Login /> } /> 
        <Route  path="/signup" element={ <SignUp /> } /> 
        {/* <Route  path="/you" element={ <YouTubeAuth /> } />  */}
       
          


        </Routes>
        </Box>
      <Box>
        
        
        </Box>
        
        </Box>
      
        </Router>
        </ThemeProvider>
       
  );
}

export default App;
