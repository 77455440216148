import React, { useState, useEffect, useMemo } from 'react'
import { Box, Stack, Container, Grid, TextField, Button, Dialog, DialogActions, DialogContent, } from '@mui/material'
import axios from "axios"
import useLocalStorage from 'use-local-storage';
import SendIcon from '@mui/icons-material/Send';
import { CirclesWithBar } from 'react-loader-spinner';
import { createEditor, Descendant } from 'slate'
import { Slate, Editable, withReact } from 'slate-react'
import { withHistory } from 'slate-history'
import VoiceCard from "../Component/VoiceCard"
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

function UploadShortAudio() {

    const [prompt, setPrompt] = useState('');
    const [finalprompt, setFinalPrompt] = useState('');
    const [open, setOpen] = useState(false);
    const [fullWidth, setFullWidth] = useState(true);
    const [maxWidth, setMaxWidth] = useState('md');
    const [query, setQuery] = useState('');
    const [videos, setVideos] = useState([]);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [selectedVoice, setSelectedVoice] = useState(null);
    const [finalVideoDOne, setfinalVideoDOne] = useState("");
    // const [backendPrompt, setSackendPrompt] = useState('');
    const [openAiApi, setOpenAiApi] = useLocalStorage('openai', '');
    const [elevenLabsApi, setelevenLabsApi] = useLocalStorage('elevenlab', '');
    const [loading, setLoading] = useState(false)
    const [loading1, setLoading1] = useState(false)
    const [loading2, setLoading2] = useState(false)
    const [messageerror, setMessageError] = useState("");
    const [Audio, setAudio] = useState(null);
    const [openSnackBar, setOpenSnackBar] = React.useState(false);
    const [conversationHistory, setConversationHistory] = useState([]);
    // console.log(backendPrompt, "backendPrompt")



    // console.log(selectedVideo?.video_files[3].link)
    // console.log(selectedVoice, "selectedVoice")

    // const handleSubmit = () => {


    //     if (prompt.length < 4) {
    //         console.log('Prompt length must be at least 4 characters.');
    //         return;
    //     }

    //     if (openAiApi.length < 4) {
    //         console.log('OpenAi Api length must be at least 4 characters.');
    //         return;
    //     }

    //     setLoading(true)

    //     axios.post('https://copyvid-drsr2tzggq-nn.a.run.app/chat', { message: prompt, openAiApi })
    //         .then(response => {
    //             console.log(response);
    //             // Handle success response
    //             // setSackendPrompt(response.data.respo.text)
    //             setFinalPrompt(response.data.respo.text)
    //             setLoading(false)
    //         })
    //         .catch(error => {
    //             console.error(error);
    //             // Handle error response
    //             setLoading(false)
    //         });
    // };

    const handleSubmit = () => {
        if (prompt.length < 4) {
          console.log('Message length must be at least 4 characters.');
          return;
        }
    
        if (openAiApi.length < 4) {
          console.log('OpenAi Api length must be at least 4 characters.');
          return;
        }
    
        setLoading(true);
    
        axios
          .post('https://copyvid-drsr2tzggq-nn.a.run.app/chat', {
            message: prompt,
            openAiApi: openAiApi,
            conversationHistory: conversationHistory,
          })
          .then((response) => {
            console.log(response);
            // Handle success response
            const aiResponse = response.data.message;
    
            // Update conversation history
            setConversationHistory([
              ...conversationHistory,
              { role: 'user', message: prompt },
              { role: 'assistant', message: aiResponse },
            ]);
    
            // Clear the input field
            // setMessage('');
    
            // Update response display
            setFinalPrompt(aiResponse);
    
            setLoading(false);
          })
          .catch((error) => {
            console.error(error);
            // Handle error response
            // setResponse('Error: Unable to get a response from the assistant.');
            setLoading(false);
          });
      };

    var handleChange = (newValue) => {
        setFinalPrompt(newValue[0].children[0].text)

    };

    useEffect(() => {
        console.log(finalprompt);
    }, [finalprompt]);





    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };





    const handleSearch = async () => {
        setLoading1(true);

        try {
            // Call the Pexels API
            const pexelsResponse = await axios.get('https://api.pexels.com/videos/search', {
                headers: {
                    Authorization: 'PHTBUSIj55aRxjUWtGHTFcQdnhinQusTl1V3swY4YHmSfADbIRbmDQ2u',
                },
                params: {
                    query: query,
                    page: 1,
                    per_page: 20,
                },
            });

            // Call the Pixabay API
            const pixabayResponse = await axios.get('https://pixabay.com/api/videos/', {
                params: {
                    // key: '33799595-4e9acaeecee2ae409bad61af3',
                    key: '34485150-83c753ff4c0d51c0fa6bd116b',
                    q: query,
                    page: 1,
                    per_page: 20
                },
            });

            // Merge the responses and set the videos state
            const mergedResponse = [...pexelsResponse.data.videos, ...pixabayResponse.data.hits];
            console.log(mergedResponse)
            setVideos(mergedResponse);

            setLoading1(false);

        } catch (error) {
            console.log(error);
            setLoading1(false);
        }
    };


    const handleCloseSnackBarFunction = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpenSnackBar(false);
    };


    const handleChangeVideos = (event) => {
        console.log("event.target.value", event.target.value)
        setQuery(event.target.value);
    };


    const handleAudioChange = (e) => {
        setAudio(e.target.files[0]);
    };


    console.log(selectedVideo?.videos?.large?.url, "selectedVideo.videos.large.url")
    console.log(finalprompt, "finalprompt", openAiApi, "openAiApi", selectedVideo?.video_files?.[3]?.link, selectedVoice, "selectedVoice")

    const handleFinalSubmit = async () => {

        if (!Audio || !selectedVideo) {
            setOpenSnackBar(true);
            return;
        }



        if (Audio && selectedVideo) {
            let finallink;
            if (selectedVideo.videos?.large?.url) {
                finallink = selectedVideo.videos.large.url;
                console.log(finallink, "finallink is");
            } else {
                const videoFiles = selectedVideo.video_files;
                console.log(videoFiles);
                videoFiles.sort((a, b) => b.width - a.width);
                const preferredWidths = [1920, 1280, 960];
                const preferredFile = videoFiles.find((file) =>
                    preferredWidths.includes(file.width)
                );
                finallink = preferredFile?.link || videoFiles[0].link;
            }

            console.log(finallink, "finallink");
            setLoading2(true);

            const formData = new FormData();
            formData.append("audio", Audio);
            formData.append("videoUrl", finallink);

            axios
                .post("https://copyvid-drsr2tzggq-nn.a.run.app/uploadshortaudio", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((response) => {
                    console.log(response);
                    console.log(response.data.url);
                    setfinalVideoDOne(response.data.url);
                    setLoading2(false);
                })
                .catch((error) => {
                    console.log(error);
                    setLoading2(false);
                    setMessageError(`Error ${error.response.status}: ${error.response.data.error}`);
                });
        } else {
            setLoading2(false);
        }
    };




    return (
        <Box className="mainBox" mt={2}>
            <Box pb={5}>
                <Container maxWidth="xl">

                    <Box my={1} >
                        <Grid container spacing={2}>
                            <Grid item xs={10}>
                                <TextField label="Prompt" variant="outlined" multiline rows={3} style={{ width: '100%', }} value={prompt} onChange={(event) => setPrompt(event.target.value)} required />
                            </Grid>
                            <Grid item xs={2}>
                                <TextField label="OpenAi Api" variant="outlined" style={{ width: '100%', marginBottom: '5px', }} value={openAiApi} onChange={(event) => setOpenAiApi(event.target.value)} required />

                                <Button variant="contained" style={{ width: '100%', height: '40%' }} onClick={handleSubmit}>Submit</Button>
                            </Grid>

                        </Grid>
                    </Box>

                    {loading ? <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" style={{ minHeight: '50vh' }} >
                        <Grid item xs={12}>
                            <CirclesWithBar color="#4868ff" ariaLabel="falling-lines-loading" />
                        </Grid> </Grid> : null}


                    <Box style={{ width: '100%', }} mt={3}>
                        {/* <Box style={{ width: '100%', backgroundColor: '#fff', minHeight: '200px' }} p={1} mt={3}> */}
                        {/* <PlainTextExample /> */}
                        <TextField
                            id="outlined-multiline-flexible" style={{ width: '100%' }}
                            label=""
                            multiline
                            value={finalprompt}
                            onChange={(e) => setFinalPrompt(e.target.value)}
                            rows={8}
                        // maxRows={14}
                        />
                    </Box>
                    {/* {finalprompt.length > 2 ? (    ) : null} */}



                    <Box mt={3}>

                        <Grid container spacing={2}>
                            <Grid item xs={5}>

                                <Button variant="outlined" onClick={handleClickOpen} style={{ width: '100%', height: '100%' }}>
                                    Select Video
                                </Button>
                                <Dialog
                                    fullWidth={fullWidth}
                                    maxWidth={maxWidth}
                                    open={open}
                                    onClose={handleClose}
                                >

                                    <DialogContent>


                                        <Grid container spacing={1}>
                                            <Grid container item xs={9}>
                                                <TextField label="Search Keyword" variant="outlined" style={{ width: '100%', backgroundColor: '#fff' }} required onChange={handleChangeVideos} />
                                            </Grid>
                                            <Grid container item xs={3}>
                                                <Stack direction="row" spacing={2}>
                                                    <Button variant="contained" endIcon={<SendIcon />} onClick={handleSearch}>
                                                        Send
                                                    </Button>
                                                </Stack>
                                            </Grid>
                                        </Grid>

                                        {loading1 ? <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" style={{ minHeight: '50vh' }} >
                                            <Grid item xs={12}>
                                                <CirclesWithBar color="#4868ff" ariaLabel="falling-lines-loading" />
                                            </Grid> </Grid> : null}


                                        <Box mt={2} className="mainvideosBox">
                                            <Grid container spacing={2}>
                                                {videos.map((video) => (
                                                    <Grid key={video.id} item xs={4}>
                                                        <Box>
                                                            <video controls
                                                                onClick={() => setSelectedVideo(video)}
                                                                style={{ border: selectedVideo === video ? "3px solid #4868ff" : "none" }}
                                                            >
                                                                <source src={video?.video_files?.[1]?.link || video.videos.large.url} type="video/mp4" />
                                                            </video>
                                                        </Box>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </Box>


                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={handleClose}>Close</Button>
                                    </DialogActions>
                                </Dialog>

                            </Grid>

                            <Grid item xs={5}>
                                <Button
                                    variant="outlined"
                                    component="label"
                                    style={{ width: '100%', height: '100%' }}
                                >
                                    Upload Audio
                                    <input
                                        hidden
                                        accept=".mp3"
                                        multiple
                                        type="file"
                                        onChange={handleAudioChange}
                                    />
                                </Button>

                            </Grid>
                            <Grid item xs={2}>
                                <Button variant="contained" style={{ width: '100%', height: '100%' }} onClick={handleFinalSubmit}>Submit</Button>
                            </Grid>

                        </Grid>
                    </Box>

                    <Snackbar open={openSnackBar} autoHideDuration={6000} onClose={handleCloseSnackBarFunction}>
                        <Alert onClose={handleCloseSnackBarFunction} severity="error" sx={{ width: "100%" }}>
                            Please upload audio and select a video.
                        </Alert>
                    </Snackbar>

                    {/* // ) : // {finalprompt.length > 2 ? (  null}  */}

                    {loading2 ? <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" style={{ minHeight: '50vh' }} >
                        <Grid item xs={12}>
                            <CirclesWithBar color="#4868ff" ariaLabel="falling-lines-loading" />
                        </Grid> </Grid> : null}


                    {messageerror.length > 0 ? (
                        <Box mt={5} display="flex" justifyContent="center">
                            <Box className='errorHeading'> {messageerror}</Box>
                        </Box>
                    ) : null
                    }




                    {finalVideoDOne.length > 0 ? (
                        <Box>
                            <Box mt={3} display="flex" justifyContent="center">

                                <Box className='shortsVideoBox'>


                                    <video controls className='mainvideo'>
                                        <source src={finalVideoDOne} type="video/mp4" width='100%' height='100%' />
                                    </video>

                                </Box>


                            </Box>
                            <Box display="flex" justifyContent="center" mt={2} pb={6}>
                                <Button
                                    variant="contained"
                                    href={finalVideoDOne}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    download
                                >
                                    Download Video
                                </Button></Box>

                            <Box mt={1} display="flex" justifyContent="center" pb={6}>
                                <Box className='mainHeadingbox'> To create new video, please refresh page and submit again</Box>
                            </Box>


                        </Box>

                    ) : null}


                </Container>
            </Box>
        </Box>
    )
}


export default UploadShortAudio