import React, { useState, useEffect, useMemo } from 'react'
import { Box, Stack, Container, Grid, TextField, Button, Dialog, DialogActions, DialogContent,  } from '@mui/material'
import axios from "axios"
import useLocalStorage from 'use-local-storage';
import SendIcon from '@mui/icons-material/Send';
import { CirclesWithBar } from  'react-loader-spinner';
import { createEditor, Descendant } from 'slate'
import { Slate, Editable, withReact } from 'slate-react'
import { withHistory } from 'slate-history'
import VoiceCard from "../Component/VoiceCard"


function ShortsMaker() {

  const [prompt, setPrompt] = useState('');
  const [finalprompt, setFinalPrompt] = useState('');
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState('md');
  const [fullWidth1, setFullWidth1] = useState(true);
  const [maxWidth1, setMaxWidth1] = useState('md');
  const [query, setQuery] = useState('');
  const [videos, setVideos] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [selectedVoice, setSelectedVoice] = useState(null);
  const [finalVideoDOne, setfinalVideoDOne] = useState("");
  // const [backendPrompt, setSackendPrompt] = useState('');
  const [openAiApi, setOpenAiApi] = useLocalStorage('openai', '');
  const [elevenLabsApi, setelevenLabsApi] = useLocalStorage('elevenlab', '');
  const [loading, setLoading] = useState(false)
  const [loading1, setLoading1] = useState(false)
  const [loading2, setLoading2] = useState(false)
  const [messageerror, setMessageError] = useState("");
  // console.log(backendPrompt, "backendPrompt")
  const [conversationHistory, setConversationHistory] = useState([]);



  // console.log(selectedVideo?.video_files[3].link)
  // console.log(selectedVoice, "selectedVoice")

  // const handleSubmit = () => {


  //   if (prompt.length < 4) {
  //     console.log('Prompt length must be at least 4 characters.');
  //     return;
  //   }

  //   if (openAiApi.length < 4) {
  //     console.log('OpenAi Api length must be at least 4 characters.');
  //     return;
  //   }

  //   setLoading(true)

  //   axios.post('https://chatgptpromptai.onrender.com/chat', { message: prompt, openAiApi })
  //     .then(response => {
  //       console.log(response);
  //       // Handle success response
  //       // setSackendPrompt(response.data.respo.text)
  //       setFinalPrompt(response.data.respo.text)
  //       setLoading(false)
  //     })
  //     .catch(error => {
  //       console.error(error);
  //       // Handle error response
  //       setLoading(false)
  //     });
  // };

  const handleSubmit = () => {
    if (prompt.length < 4) {
      console.log('Message length must be at least 4 characters.');
      return;
    }

    if (openAiApi.length < 4) {
      console.log('OpenAi Api length must be at least 4 characters.');
      return;
    }

    setLoading(true);

    axios
      .post('https://copyvid-drsr2tzggq-nn.a.run.app/chat', {
        message: prompt,
        openAiApi: openAiApi,
        conversationHistory: conversationHistory,
      })
      .then((response) => {
        console.log(response);
        // Handle success response
        const aiResponse = response.data.message;

        // Update conversation history
        setConversationHistory([
          ...conversationHistory,
          { role: 'user', message: prompt },
          { role: 'assistant', message: aiResponse },
        ]);

        // Clear the input field
        // setMessage('');

        // Update response display
        setFinalPrompt(aiResponse);

        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        // Handle error response
        // setResponse('Error: Unable to get a response from the assistant.');
        setLoading(false);
      });
  };


  var handleChange = (newValue) => {
    setFinalPrompt(newValue[0].children[0].text)

  };

  useEffect(() => {
    console.log(finalprompt);
  }, [finalprompt]);



  const PlainTextExample = () => {
    const editor = useMemo(() => withHistory(withReact(createEditor())), [])
    return (
      <Slate editor={editor} value={initialValue} onChange={handleChange}>
        <Editable placeholder="Enter some plain text..." />
      </Slate>
    )
  }

  const initialValue = [
    {
      type: 'paragraph',
      children: [
        { text: finalprompt },
      ],
    },
  ]

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const handleClickOpen1 = () => {
    setOpen1(true);
  };

  const handleClose1 = () => {
    setOpen1(false);
  };



  // const handleSearch = async () => {
  //   setLoading1(true)
  //   try {
  //     const response = await axios.get('https://api.pexels.com/videos/search', {
  //       headers: {
  //         Authorization: 'PHTBUSIj55aRxjUWtGHTFcQdnhinQusTl1V3swY4YHmSfADbIRbmDQ2u',
  //       },
  //       params: {
  //         query: query,
  //         per_page: 10,
  //       },
  //     });
  //     console.log(response.data)

  //     setVideos(response.data.videos);
  //     setLoading1(false)
  //   } catch (error) {
  //     console.log(error);
  //     setLoading1(false)
  //   }
  // };


  // const handleSearch = async () => {
  //   setLoading1(true);
  
  //   try {
  //     // Call the Pexels API
  //     const pexelsResponse = await axios.get('https://api.pexels.com/videos/search', {
  //       headers: {
  //         Authorization: 'PHTBUSIj55aRxjUWtGHTFcQdnhinQusTl1V3swY4YHmSfADbIRbmDQ2u',
  //       },
  //       params: {
  //         query: query,
  //         per_page: 10,
  //       },
  //     });
  //     console.log(pexelsResponse.data.videos, "pexelsResponse.data");
  //     setVideos(pexelsResponse.data.videos);
  
  //     // Call the Pixabay API
  //     const pixabayResponse = await axios.get('https://pixabay.com/api/videos/', {
  //       params: {
  //         key: '33799595-4e9acaeecee2ae409bad61af3',
  //         q: query,
  //       },
  //     });
   
  //     console.log(pixabayResponse.data.hits, "pixabayResponse.data.hits");
  //     // setVideos(prevVideos => [...prevVideos, ...pixabayResponse.data.hits]);
      
  
  //     setLoading1(false);
      
  //   } catch (error) {
  //     console.log(error);
  //     setLoading1(false);
     
  //   }
  // };
  
  const handleSearch = async () => {
    setLoading1(true);
  
    try {
      // Call the Pexels API
      const pexelsResponse = await axios.get('https://api.pexels.com/videos/search', {
        headers: {
          Authorization: 'PHTBUSIj55aRxjUWtGHTFcQdnhinQusTl1V3swY4YHmSfADbIRbmDQ2u',
        },
        params: {
          query: query,
          page: 1,
          per_page: 20
        },
      });
  
      // Call the Pixabay API
      const pixabayResponse = await axios.get('https://pixabay.com/api/videos/', {
        params: {
          // key: '33799595-4e9acaeecee2ae409bad61af3',
          key: '34485150-83c753ff4c0d51c0fa6bd116b',
          q: query,
          page: 1,
          per_page: 20
        },
      });
  
      // Merge the responses and set the videos state
      const mergedResponse = [...pexelsResponse.data.videos, ...pixabayResponse.data.hits];
      console.log(mergedResponse)
      setVideos(mergedResponse);
  
      setLoading1(false);
  
    } catch (error) {
      console.log(error);
      setLoading1(false);
    }
  };
  




  const handleChangeVideos = (event) => {
    console.log("event.target.value", event.target.value)
    setQuery(event.target.value);
  };


  const voicearr = [
    {
      "voice_id": "21m00Tcm4TlvDq8ikWAM",
      "name": "Rachel (american, mellow)",
      "samples": [],
      "category": "conversational",
      "preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/21m00Tcm4TlvDq8ikWAM/dff5d82d-d16d-45b9-ae73-be2ad8850855.mp3",
      "available_for_tiers": [],
      "settings": null
    },
    {
      "voice_id": "AZnzlk1XvdvUeBnXmlld",
      "name": "Domi (american, engaged)",
      "samples": [],
      "category": "dynamic",
      "preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/AZnzlk1XvdvUeBnXmlld/53bd2f5f-bb59-4146-9922-245b2a466c80.mp3",
      "available_for_tiers": [],
      "settings": null
    },
    {
      "voice_id": "EXAVITQu4vr4xnSDxMaL",
      "name": "Bella (American, soft)",
      "samples": [],
      "category": "conversational",
      "preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/EXAVITQu4vr4xnSDxMaL/53bd2f5f-bb59-4146-8822-245b2a466c80.mp3",
      "available_for_tiers": [],
      "settings": null
    },
    {
      "voice_id": "ErXwobaYiN019PkySvjV",
      "name": "Antoni (American, modulated)",
      "samples": [],
      "category": "conversational",
      "preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/ErXwobaYiN019PkySvjV/53bd2f5f-bb59-1111-8822-225b2a466c80.mp3",
      "available_for_tiers": [],
      "settings": null
    },
    {
      "voice_id": "MF3mGyEYCl7XYWbV9V6O",
      "name": "Elli (american, clear)",
      "samples": [],
      "category": "dynamic",
      "preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/MF3mGyEYCl7XYWbV9V6O/bea2dc16-9abf-4162-b011-66531458e022.mp3",
      "available_for_tiers": [],
      "settings": null
    },
    {
      "voice_id": "TxGEqnHWrfWFTfGW9XjX",
      "name": "Josh (american, silvery)",
      "samples": [],
      "category": "conversational",
      "preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/TxGEqnHWrfWFTfGW9XjX/bdc4303c-a20d-4cec-97eb-dca625044eac.mp3",
      "available_for_tiers": [],
      "settings": null
    },
    {
      "voice_id": "VR6AewLTigWG4xSOukaG",
      "name": "Arnold (american, nasal)",
      "samples": [],
      "category": "conversational",
      "preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/VR6AewLTigWG4xSOukaG/2c4395e7-91b1-44cd-8f0f-e4aebd292461.mp3",
      "available_for_tiers": [],
      "settings": null
    },
    {
      "voice_id": "pNInz6obpgDQGcFmaJgB",
      "name": "Adam (American, clear)",
      "samples": [],
      "category": "conversational",
      "preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/pNInz6obpgDQGcFmaJgB/53bd2f5f-bb59-1111-8822-245b2a466c80.mp3",
      "available_for_tiers": [],
      "settings": null
    },
    {
      "voice_id": "yoZ06aMxZJJ28mfd3POQ",
      "name": "Sam (american, dynamic)",
      "samples": [],
      "category": "dynamic",
      "preview_url": "https://storage.googleapis.com/eleven-public-prod/premade/voices/yoZ06aMxZJJ28mfd3POQ/1c4d417c-ba80-4de8-874a-a1c57987ea63.mp3",
      "available_for_tiers": [],
      "settings": null
    }
  ]



  // const handleFinalSubmit = () => {

  //   setLoading2(true)

  //   const videoFiles = selectedVideo?.video_files;
  //   console.log(videoFiles)
  //   videoFiles.sort((a, b) => b.width - a.width);
  //   const preferredWidths = [1920, 1280, 960];
  //   const preferredFile = videoFiles.find(file => preferredWidths.includes(file.width));
  //   const finallink = preferredFile?.link || videoFiles[0].link;
  //   console.log(finallink, "finallink")

  //   // axios.post('https://copyvid-drsr2tzggq-nn.a.run.app/remove', { voiceIdDone: selectedVoice, textDone: finalprompt, videoUrl: selectedVideo?.video_files[1].link, elevenLabsApi })
  //   axios.post('https://copyvid-drsr2tzggq-nn.a.run.app/remove', { voiceIdDone: selectedVoice, textDone: finalprompt, videoUrl: finallink, elevenLabsApi })
  //     .then(response => {
  //       console.log(response);
  //       console.log(response.data.url);
  //       setfinalVideoDOne(response.data.url)
  //       setLoading2(false)
  //       // setFinalPrompt(response.data.respo.text)
  //     })
  //     .catch(error => {
  //       console.error(error);
  //       setLoading2(false)
  //       // Handle error response
  //     });
  // };

 


  console.log(selectedVideo?.videos?.large?.url, "selectedVideo.videos.large.url")
  console.log(finalprompt, "finalprompt", openAiApi, "openAiApi", selectedVideo?.video_files?.[3]?.link, selectedVoice, "selectedVoice")

  const handleFinalSubmit = () => {

    if (selectedVoice && elevenLabsApi && selectedVideo) {
  
     
  
      let finallink;
      if (selectedVideo.videos?.large?.url) {
        finallink = selectedVideo.videos.large.url;
        console.log(finallink, "finallink is")
      } else {
        const videoFiles = selectedVideo.video_files;
        console.log(videoFiles);
        videoFiles.sort((a, b) => b.width - a.width);
        const preferredWidths = [1920, 1280, 960];
        const preferredFile = videoFiles.find((file) =>
          preferredWidths.includes(file.width)
        );
        finallink = preferredFile?.link || videoFiles[0].link;
      }
  
      console.log(finallink, "finallink");
      setLoading2(true);
      axios
        .post("https://copyvid-drsr2tzggq-nn.a.run.app/shorts", {
          voiceIdDone: selectedVoice,
          textDone: finalprompt,
          videoUrl: finallink,
          elevenLabsApi,
        })
        .then((response) => {
          console.log(response);
          console.log(response.data.url);
          setfinalVideoDOne(response.data.url);
          setLoading2(false);
          // setFinalPrompt(response.data.respo.text)
        })
        .catch((error) => {
          console.error(error);
          setLoading2(false);
          setMessageError(`Error ${error.response.status}: ${error.response.data.error}`);
          // Handle error response
        });
    } else {
      setLoading2(false);
    }
  };
  
  



  // const handleFinalSubmit = () => {
   
  
  //   if (selectedVoice && elevenLabsApi && selectedVideo) {

  //     setLoading2(true);
  
  //     const videoFiles = selectedVideo?.video_files;
  //     console.log(videoFiles);
  //     videoFiles.sort((a, b) => b.width - a.width);
  //     const preferredWidths = [1920, 1280, 960];
  //     const preferredFile = videoFiles.find((file) =>
  //       preferredWidths.includes(file.width)
  //     );
  //     const finallink = preferredFile?.link || videoFiles[0].link;
  //     console.log(finallink, "finallink");


  //     axios
  //       .post("https://copyvid-drsr2tzggq-nn.a.run.app/remove", {
  //         voiceIdDone: selectedVoice,
  //         textDone: finalprompt,
  //         videoUrl: finallink,
  //         elevenLabsApi,
  //       })
  //       .then((response) => {
  //         console.log(response);
  //         console.log(response.data.url);
  //         setfinalVideoDOne(response.data.url);
  //         setLoading2(false);
  //         // setFinalPrompt(response.data.respo.text)
  //       })
  //       .catch((error) => {
  //         console.error(error);
  //         setLoading2(false);
  //         // Handle error response
  //       });
  //   } else {
  //     setLoading2(false);
  //   }
  // };
  


  return (
    <Box className="mainBox" mt={2}>
      <Box pb={5}>
        <Container maxWidth="xl">

          <Box my={1} >
            <Grid container spacing={2}>
              <Grid item xs={10}>
                <TextField label="Prompt" variant="outlined" multiline rows={3} style={{ width: '100%', }} value={prompt} onChange={(event) => setPrompt(event.target.value)} required />
              </Grid>
              <Grid item xs={2}>
                <TextField label="OpenAi Api" variant="outlined" style={{ width: '100%', marginBottom: '5px', }} value={openAiApi} onChange={(event) => setOpenAiApi(event.target.value)} required />

                <Button variant="contained" style={{ width: '100%', height: '40%' }} onClick={handleSubmit}>Submit</Button>
              </Grid>

            </Grid>
          </Box>

          {loading ? <Grid   container   spacing={0}   direction="column"   alignItems="center"   justifyContent="center"   style={{ minHeight: '50vh' }} >
                    <Grid item xs={12}>
                    <CirclesWithBar color="#4868ff" ariaLabel="falling-lines-loading" />
                     </Grid> </Grid>: null }

          
            <Box style={{ width: '100%', }} mt={3}>
              {/* <Box style={{ width: '100%', backgroundColor: '#fff', minHeight: '200px' }} p={1} mt={3}> */}
              {/* <PlainTextExample /> */}
              <TextField
                id="outlined-multiline-flexible" style={{ width: '100%' }}
                label=""
                multiline
                value={finalprompt}
                onChange={(e) => setFinalPrompt(e.target.value)}
                rows={8}
              // maxRows={14}
              />
            </Box>
          


          
            <Box mt={3}>

              <Grid container spacing={2}>
                <Grid item xs={4}>

                  <Button variant="outlined" onClick={handleClickOpen} style={{ width: '100%', height: '100%' }}>
                    Select Video
                  </Button>
                  <Dialog
                    fullWidth={fullWidth}
                    maxWidth={maxWidth}
                    open={open}
                    onClose={handleClose}
                  >

                    <DialogContent>


                      <Grid container spacing={1}>
                        <Grid container item xs={9}>
                          <TextField label="Search Keyword" variant="outlined" style={{ width: '100%', backgroundColor: '#fff' }} required onChange={handleChangeVideos} />
                        </Grid>
                        <Grid container item xs={3}>
                          <Stack direction="row" spacing={2}>
                            <Button variant="contained" endIcon={<SendIcon />} onClick={handleSearch}>
                              Send
                            </Button>
                          </Stack>
                        </Grid>
                      </Grid>

                      {loading1 ? <Grid   container   spacing={0}   direction="column"   alignItems="center"   justifyContent="center"   style={{ minHeight: '50vh' }} >
                    <Grid item xs={12}>
                    <CirclesWithBar color="#4868ff" ariaLabel="falling-lines-loading" />
                     </Grid> </Grid>: null }


                      <Box mt={2} className="mainvideosBox">
                        <Grid container spacing={2}>
                          {videos.map((video) => (
                            <Grid key={video.id} item xs={4}>
                              <Box>
                                <video controls
                                  onClick={() => setSelectedVideo(video)}
                                  style={{ border: selectedVideo === video ? "3px solid #4868ff" : "none" }}
                                >
                                  <source src={video?.video_files?.[1]?.link || video.videos.large.url} type="video/mp4" />
                                </video>
                              </Box>
                            </Grid>
                          ))}
                        </Grid>
                      </Box>


                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose}>Close</Button>
                    </DialogActions>
                  </Dialog>

                </Grid>

                <Grid item xs={4}>
                  <Button variant="outlined" onClick={handleClickOpen1} style={{ width: '100%', height: '100%' }}>
                    Select Voice
                  </Button>
                  <Dialog
                    fullWidth={fullWidth1}
                    maxWidth={maxWidth1}
                    open={open1}
                    onClose={handleClose1}
                  >

                    <DialogContent>
                   

                      <h3>Select a voice</h3>

                      {/* <Box className='backgroundVoice' mt={2}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Box px={1} pb={1}>
                           
                              <VoiceCard name="Rachel (american, mellow)" category="conversational" audiomp3="https://storage.googleapis.com/eleven-public-prod/premade/voices/21m00Tcm4TlvDq8ikWAM/dff5d82d-d16d-45b9-ae73-be2ad8850855.mp3" />
                              
                          </Box>
                        </Grid>
                      </Grid>
                    </Box> */}


                      <Box className='backgroundVoice' mt={3}>
                        <Grid container spacing={2}>
                          {voicearr.map((voice) => (
                            <Grid item xs={4} key={voice.voice_id}>
                              <Box px={1} pb={1} onClick={() => setSelectedVoice(voice.voice_id)} className={selectedVoice === voice.voice_id ? "selected" : ""}>
                                <VoiceCard
                                  name={voice.name}
                                  category={voice.category}
                                  audiomp3={voice.preview_url}
                                />
                              </Box>
                            </Grid>
                          ))}
                        </Grid>
                      </Box>


                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose1}>Close</Button>
                    </DialogActions>
                  </Dialog>

                </Grid>
                <Grid item xs={2}>
                  <TextField label="ElevenLabs Api" variant="outlined" style={{ width: '100%', }} value={elevenLabsApi} onChange={(event) => setelevenLabsApi(event.target.value)} required />

                </Grid>
                <Grid item xs={2}>
                  <Button variant="contained" style={{ width: '100%', height: '100%' }} onClick={handleFinalSubmit}>Submit</Button>
                </Grid>

              </Grid>
            </Box>

{/* {finalprompt.length > 2 ? (  ) : null} {finalprompt.length > 2 ? ( ) : null} */}

{loading2 ? <Grid   container   spacing={0}   direction="column"   alignItems="center"   justifyContent="center"   style={{ minHeight: '50vh' }} >
                    <Grid item xs={12}>
                    <CirclesWithBar color="#4868ff" ariaLabel="falling-lines-loading" />
                     </Grid> </Grid>: null }


                     {messageerror.length > 0 ? (
                      <Box mt={5} display="flex" justifyContent="center">
        <Box className='errorHeading'> {messageerror}</Box>
        </Box>
         ) : null
        }


          {finalVideoDOne.length > 0 ? (

            <Box mt={3}>



              <Box display="flex" justifyContent="center" > 
              <Box className='shortsVideoBox'>
<video controls className='mainvideo'>
                <source src={finalVideoDOne} type="video/mp4" width='100%' height='100%' />
              </video>
</Box></Box>

              <Box display="flex" justifyContent="center" mt={2} pb={6}>
                <Button
                  variant="contained"
                  href={finalVideoDOne}
                  target="_blank"
                  rel="noopener noreferrer"
                  download
                >
                  Download Video
                </Button></Box>

                <Box mt={1} display="flex" justifyContent="center" pb={6}>
        <Box className='mainHeadingbox'> To create new video, please refresh page and submit again</Box>
        </Box>


            </Box>


          ) : null}


        </Container>
      </Box>
    </Box>
  )
}

export default ShortsMaker